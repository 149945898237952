.ant-btn-link.pdf-viewer-custom-button-icon {
	color: black;
	border-radius: 0.25rem;
}

.ant-btn.ant-btn-icon-only.ant-btn-link.pdf-viewer-custom-button-icon:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.pdf-viewer-popover.ant-popover .ant-popover-inner {
	background-color: #000000;
}

.pdf-viewer-popover.ant-popover .ant-popover-inner .ant-popover-inner-content {
	color: white;
}

.rpv-core__minimal-button {
	display: flex;
	align-items: center;
}
