.pbi-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
	background-color: rgba(17, 29, 44, 0.7);
}

.pbi-button-icon {
	cursor: pointer;
	/* margin-right: 25px; */
	height: 16.5px;
	color: rgba(255, 255, 255, 0.85);
}
