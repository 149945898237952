@import "./assets/css/App_dark.css";
@import "./assets/css/mobile.css";
@import "../node_modules/file-icons-js/css/style.css";
@import "../node_modules/react-grid-layout/css/styles.css";
@import "../node_modules/react-resizable/css/styles.css";
@import "./assets/css/ReactQuill.css";

.file-icons::before {
	font-size: 20px !important;
}

canvas {
	outline: none;
}

svg {
	max-width: 100%;
	vertical-align: center;
}

@keyframes blinking {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/***Antd CSS***/

/**
 * Remove focus styles for non-keyboard focus.
 */
:focus:not(:focus-visible) {
	outline: 0;
	box-shadow: none;
}

/**
   * Cross-browser styles for explicit focus via 
   * keyboard-based (eg Tab) navigation or the
   * .focus-visible utility class.
   */

/* :focus,
.focus-visible:focus:not(:focus-visible) {
	outline: 0;
	box-shadow: 0 0 0 0.2rem #fff, 0 0 0 0.35rem #069;
} */

.portal-logo {
	width: 120px;
	height: 50px;
	/* margin: 7px 0 5px 25px; */
	/* position: absolute;
  top:calc(100vh - 100px) !important; */
}

.portal-logo-mobile {
	width: 120px;
	height: 50px;
	margin: 7px 0px;
	/* position: absolute;
  top:calc(100vh - 100px) !important; */
}

.portal-small-logo {
	width: 50px;
	height: 50px;
	/* margin: 7px 0 5px 15px; */
	/* position: absolute;
  top:calc(100vh - 100px) !important; */
}

.ant-row-rtl .logo {
	float: right;
	margin: 16px 0 16px 24px;
	background-color: #004a93;
}

/*Sider Menu */

.ant-layout-sider-children {
	height: 100% !important;
	overflow: auto;
}

.ant-layout-sider-children::-webkit-scrollbar {
	width: 1px;
	display: none;
}

.auto-scroll-view-container::-webkit-scrollbar {
	display: none;
}

.mega-menu-sidebar-wrapper .ant-layout-sider-children::-webkit-scrollbar-thumb {
	background-color: #0049935d;
	background-color: #177ddc;
	border-radius: 4px;

	/* outline: 1px solid rgb(5, 20, 35); */
}

.mega-menu-sidebar-wrapper .ant-layout-sider-children::-webkit-scrollbar {
	width: 4px;
	display: block;
}

/* .mega-menu-submenu {
	inset: 0 !important;
	top: 24.5px !important;
} */

.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected:hover {
	background: transparent;
}

.ant-layout-sider-children::-webkit-scrollbar-track {
	background: #444;
}

.ant-picker-dropdown-range {
	z-index: 30000;
}

/*Sider Menu items */
.ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-top {
	z-index: 30004;
}

.ant-tooltip {
	--antd-arrow-background-color: #1f1f1f;
}

.ant-tooltip .ant-tooltip-inner {
	background-color: #1f1f1f;
}

.ant-menu.ant-menu-dark {
	background: rgba(0, 0, 0, 0);
}

.ant-menu-submenu-popup .ant-menu-title-content {
	min-width: 140px;
}

.ant-menu-submenu > .ant-menu .ant-menu-item img + span {
	margin-left: 10px;
}

.main-layout-wrapper::-webkit-scrollbar {
	display: none;
}

.main-sider .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.main-sider
	.ant-menu.ant-menu-inline-collapsed
	> .ant-menu-submenu
	> .ant-menu-submenu-title {
	padding-top: 15px;
	display: flex;
	flex-direction: column;
}

.main-sider
	.ant-menu.ant-menu-inline-collapsed
	.ant-menu-submenu
	.ant-menu-submenu-title
	.anticon
	+ span,
.main-sider .ant-menu.ant-menu-inline-collapsed .ant-menu-item .anticon + span,
.main-sider
	.ant-menu.ant-menu-inline-collapsed
	> .ant-menu-item
	.ant-menu-item-icon
	+ span {
	white-space: inherit;
	word-break: inherit;
}

.ant-layout-sider-children {
	height: 0;
}

/*Sider Menu items with children */
.main-sider .ant-menu-dark .ant-menu-item,
.main-sider .ant-menu-dark .ant-menu-submenu-title {
	height: 54px;
}

.map-assets-tab::-webkit-scrollbar {
	width: 2px;
	height: 8px;
}

.map-assets-tab::-webkit-scrollbar-track {
	background: #555;
}

.map-assets-tab::-webkit-scrollbar-thumb {
	background: #dddcdc;
	border-radius: 5px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
	margin-top: 0px !important;
	top: 0px;
}

.ant-layout .ant-layout-sider-trigger {
	height: 45px;
	color: #fff;
	background: rgba(0, 0, 0, 0);
}

.ant-layout-content {
	max-height: 100%;
	height: 100%;
	margin: 0px;
	overflow: hidden;
	background-color: white;
	display: flex;
	flex-direction: column;
}

.ant-tabs .ant-tabs-tabpane,
.ant-tabs.ant-tabs-top {
	height: inherit;
}

.ant-tabs-content-holder {
	height: calc(100% - 56px);
	display: flex;
}

.ant-tabs-content {
	height: 100%;
}

.babylon-asset-body .babylon-asset-tree {
	overflow: auto;
	height: 100%;
}

.babylon-asset-body .babylon-asset-tree::-webkit-scrollbar {
	display: none;
}

.babylon-asset-body .babylon-asset-tree .ant-tree-indent {
	width: 0px;
}

.digitaltwin-drawer-wrapper .ant-tabs-content {
	overflow: auto;
	height: auto;
}

.digitaltwin-drawer-wrapper .ant-tree .ant-tree-node-content-wrapper {
	width: auto;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.digitaltwin-drawer-wrapper
	.ant-tree
	.ant-tree-node-content-wrapper
	.ant-space-item {
	line-height: 0px;
}

.digitaltwin-drawer-wrapper
	.digitaltwin-tree-level-3
	.ant-tree-node-content-wrapper {
	background-color: #141414;
}

.digitaltwin-drawer-wrapper .babylon-tree .ant-tree-treenode {
	white-space: nowrap;
}

.BabylonPopOver {
	position: absolute;
}

.digitaltwin-drawer-wrapper .ant-tabs-content::-webkit-scrollbar {
	display: none;
}

.ant-modal-root .dt-modal-wrapper {
	pointer-events: none;
}

.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
	border-radius: 2px;
}

.ant-table-wrapper {
	height: max-content;
	/* height: 100%; */
	/* min-height: max-content; */
}

.white-table .ant-table-pagination.ant-pagination {
	background-color: transparent;
	border-top: 1px solid #f0f0f0;
}

.white-table .ant-table-pagination .ant-pagination-item {
	border: 1px solid #d9d9d9;
}

.white-table
	.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-ellipsis,
.white-table
	.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-ellipsis {
	color: rgba(0, 0, 0, 0.88);
}

.white-table .ant-table-tbody > tr.ant-table-row:hover > td,
.white-table .ant-table-tbody > tr > td.ant-table-cell-row-hover,
.white-table .ant-table-tbody > tr > td.ant-table-cell-row-selected,
.white-table .ant-table-tbody > tr.ant-table-row-selected > td {
	background-color: transparent !important;
}

.white-table .ant-pagination-prev .ant-pagination-item-link,
.white-table .ant-pagination-next .ant-pagination-item-link,
.white-table .ant-pagination-item,
.white-table .ant-pagination-item a {
	background-color: transparent;
	color: rgba(0, 0, 0, 0.88);
}

.white-table .ant-empty-description {
	color: rgba(0, 0, 0, 0.88) !important;
}

.white-table .ant-table-thead .ant-table-cell {
	background-color: #fafafa;
	color: rgba(0, 0, 0, 0.88);
}

.table-row-clickable {
	cursor: pointer;
}

.ant-table-empty,
.ant-table-container,
.ant-table-content,
table {
	height: 100%;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
	font-weight: normal;
}

.ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:last-child {
	border-start-end-radius: 0;
}

.ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:first-child {
	border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container {
	border-start-start-radius: 0;
	border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-title {
	border: none;
}

.ant-table-pagination .ant-pagination-item {
	border: 1px solid #d9d9d9;
}

.ant-table-pagination.ant-pagination {
	padding: 16px;
	margin: 0 !important;
	border: solid 1px rgb(240, 240, 240);
	border-top: none;
}

.ant-table-pagination-left {
	padding-left: 30px;
	width: 100%;
}

.ant-table-pagination-left {
	justify-content: flex-end;
	padding-left: 30px;
	width: 100%;
}

.ant-pagination-item-active {
	border-color: #1890ff !important;
}

.ant-pagination .ant-pagination-item a {
	display: inline-block;
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
	background: transparent;
	border-color: #1890ff;
}
.ant-pagination-item-link {
	border-radius: 2px !important;
}

.ant-pagination .ant-pagination-item a:hover {
	color: #1890ff;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
	display: flex;
}

.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon .anticon svg,
.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon .anticon svg {
	margin-left: 10px;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon a,
.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon a {
	align-self: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chart-marketplace::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chart-marketplace {
	display: grid;
	grid-template-columns: "repeat(auto-fill, 221px)";
	grid-auto-rows: 250px;
	gap: 10px;
	overflow: auto;
	height: 100%;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.custom-optional-text {
	color: rgba(0, 0, 0, 0.45);
}

.custom-hover {
	color: rgba(255, 255, 255, 0.85);
	background-color: rgba(255, 255, 255, 0.12);
}

.custom-dashboard-drawer .ant-spin-nested-loading {
	height: inherit;
}
/* Sider Selected item */
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	/* color: white !important; */
	/* background-color: rgb(250, 200, 195, 0.1) !important; */
	/* background: none !important; */
	/* border-radius: 0; */
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-inline)
	.ant-menu-item-selected
	.ant-typography {
	/* color: rgba(23, 125, 220, 1); */
	/* background: url("./assets/img/hex.png") !important; */
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected {
	background: none;
}

.ant-menu-dark.ant-menu-horizontal
	.ant-menu-submenu-selected
	> .ant-menu-submenu-title {
	color: #177ddc;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-inline) .ant-menu-item-selected {
	background: none !important;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mega-menu-item
	.ant-menu-item.ant-menu-item-only-child {
	padding-left: 16px !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
	border-bottom: none !important;
	transition: none !important;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mega-menu-item {
	width: 150px;
}

.subtext {
	color: rgba(0, 0, 0, 0.45);
}

.field-table,
.schema-table,
.field-selection-table,
.preview-table,
.templates-table,
.data-source-table {
	overflow: auto;
	scrollbar-color: rgba(255, 255, 255, 0.25) rgba(253, 253, 253, 0.12);
}

.primary-color,
.ant-typography.custom-statistic-value.primary-color {
	color: rgba(255, 255, 255, 0.65);
}

.secondary-color {
	color: rgba(255, 255, 255, 0.45);
}

.small-divider {
	margin: 8px 0;
}

.staggered-grid {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	/* grid-auto-rows: 200px; */
}
.slashed {
	font-family: "Fira Code", monospace !important;
}

.slicer-run-button {
	background-color: #3c9ae8;
}

.slicer-run-button:disabled {
	background-color: #646464 !important;
}

.success,
.success:not(:disabled):hover {
	/* background-color: #5cb85c !important; */
	background-color: #1765ad !important;
}

.modified,
.modified:hover {
	background-color: #e85000 !important;
}

td.measure-list-column {
	vertical-align: top;
}

.fix-header.ant-table-wrapper .ant-table .ant-table-header {
	border-radius: 0;
}

.ant-table-wrapper .ant-table .ant-table-header {
	border-radius: 0;
}

.ant-menu-submenu.ant-menu-submenu-inline.submenu .ant-menu-submenu-title {
	padding-left: 0;
}

.ant-btn,
.ant-input,
.ant-select .ant-select-selector,
.ant-select-dropdown,
.ant-input-group,
.ant-input-number,
.ant-tag,
.ant-pagination .ant-pagination-item {
	border-radius: 2px;
}

.ant-input-number {
	width: 100%;
}

.folder-footer .ant-btn.ant-btn-icon-only {
	width: auto;
}

/* mobile menu */
.ant-drawer .ant-drawer-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ant-drawer-mask {
	width: 100vw;
}

.ant-drawer .ant-drawer-body {
	padding: 12px;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
	box-shadow: none;
}

.ant-dropdown {
	z-index: 30003 !important;
}

.ant-dropdown .ant-dropdown-menu {
	border-radius: 2px;
}

/* Add styles for the dropdown menu */
.custom-dropdown .ant-dropdown-menu {
	max-height: 250px; /* Fixed height for scrollable area */
	overflow-y: auto; /* Enable scrolling when content overflows */
	position: relative; /* Prevent unnecessary shifts */
}

/* Optional: Handle scrollbar styling (if needed) */
.custom-dropdown .ant-dropdown-menu::-webkit-scrollbar {
	width: 8px;
}
.custom-dropdown .ant-dropdown-menu::-webkit-scrollbar-thumb {
	background: #dddcdc;
	border-radius: 6px;
}

.ant-dropdown-hidden {
	top: -1000px !important;
}

.ant-image-preview-operations-wrapper {
	z-index: 30000 !important;
}
.ant-image-preview-root .ant-image-preview-wrap {
	z-index: 29999 !important;
}
.ant-image-preview-root .ant-image-preview-mask {
	z-index: 29998 !important;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
	border-radius: 0;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.ant-input-search
	> .ant-input-group
	> .ant-input-group-addon:last-child
	.ant-input-search-button {
	border-radius: 0;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.marketplace_drawer_wrapper .ant-drawer-body {
	padding: 0px;
}

.digitaltwin-drawer-wrapper .ant-drawer-body::-webkit-scrollbar,
.marketplace_drawer_wrapper .ant-drawer-body::-webkit-scrollbar {
	display: none;
}

.marketplace-dashboard-search {
	margin-bottom: 10px;
}

.marketplace-dashboard-drawer .ant-drawer-footer {
	display: flex;
	padding: 10px 24px;
	background: #141414;
}

.marketplace-dashboard-drawer .ant-drawer-header-title {
	width: 100%;
	justify-content: space-between;
	flex-direction: row-reverse;
}

.marketplace-dashboard-drawer .ant-drawer-title {
	flex: none;
}

.marketplace-dashboard-drawer .ant-drawer-title {
	display: flex;
	flex-grow: 1;
	padding-right: 0.5em;
}

.marketplace-dashboard-inputs {
	margin-bottom: 1em;
}

/* .marketplace-dashboard-inputs div:not(:first-child) {
	margin-top: 0.5em;
} */

.marketplace-dashboard-input {
	border-radius: 2px;
	margin-top: 0.5em;
}

.marketplace-dashboard-input-group {
	margin-top: 0.5em;
}

.marketplace-dashboard-input.marketplace-dashboard-input-readonly
	.ant-select-selector
	.ant-select-selection-item,
.marketplace-dashboard-input.marketplace-dashboard-input-read-and-edit
	.ant-select-selector
	.ant-select-selection-item {
	border-radius: 2px;
}

* {
	font-family: "Titillium Web", sans-serif !important;
}

pre {
	font-family: "Courier New", Courier, monospace !important;
}

.ant-menu-submenu-arrow {
	color: rgba(255, 255, 255, 0.45);
}

.ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-submenu-active .ant-menu-item-icon,
.ant-menu-item-active .ant-menu-item-icon,
.ant-menu-item-active {
	color: white;
	filter: brightness(1);
}

.ant-menu-overflow-item.ant-menu-item.notification-menu-item {
	height: 40px;
	display: flex;
	border-bottom: solid 3px transparent;
	transition: background 0.3s;
}

.ant-menu-overflow-item.ant-menu-item.notification-menu-item:focus {
	background: transparent;
	border-bottom-color: #1890ff;
}

.ant-menu-item-icon {
	align-self: center;
	filter: brightness(0.65);
}

.ant-menu-submenu-popup {
	z-index: 20001;
}

.ant-menu-submenu
	> .ant-menu
	> .mobile-menu
	> .admin-menu-item
	> .ant-menu-item
	> .ant-menu-item-icon {
	color: black !important;
}

.main-sider
	.ant-layout-sider-children
	.ant-menu.ant-menu-inline-collapsed
	.antd-icon {
	width: 28px;
	margin-top: -5px;
}

.digitaltwin-drawer-wrapper {
	bottom: initial;
	left: initial;
	height: calc(100%);
}

.dropdown_icon .anticon,
.dropdown_icon .menu-img,
.dropdown_icon .menu-img-sp {
	height: 18px !important;
	font-size: 18px !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
	margin-top: -5px;
}

.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .ant-menu-item-icon {
	height: 32px !important;
	font-size: 28px !important;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
	display: flex;
	align-items: center;
	/* padding-left: 30px !important; */
}

.ant-menu.item.selected {
	color: black !important;
	background-color: #fff1f4 !important;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item .dropdown_icon {
	margin-right: -35px;
}

.refresh-button .anticon svg {
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

.run-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row-reverse;
}

.run-button .ant-btn-icon {
	margin-inline-end: 0 !important;
	margin-inline-start: 4px;
}

.ant-menu-submenu-popup .ant-menu-title-content {
	min-width: 120px;
}

.ant-menu-submenu-popup .ant-menu-title-content {
	line-height: 1.2;
}

.ant-modal-body p {
	margin: 0;
}

.ant-modal .ant-modal-header {
	border-radius: 0;
	padding: 16px 24px;
	margin-bottom: 0;
}

.ant-modal .ant-modal-body {
	padding: 24px;
}

.ant-modal .ant-modal-footer {
	padding: 10px 16px;
	margin-top: 0;
}

.ant-modal-body .site-tag-plus {
	font-size: 14px;
}

.ant-modal .ant-modal-title {
	font-weight: normal;
}

.ant-modal .ant-modal-content {
	padding: 0;
	border-radius: 2px;
}

/* .ant-modal-root .ant-modal-mask,
.ant-modal-root .ant-modal-wrap {
	z-index: 30002;
} */

.ant-select-dropdown {
	z-index: 30005;
}

.ant-switch.ant-switch-small .ant-switch-inner .ant-switch-inner-unchecked {
	margin-top: -19px;
}

.dataseries-delete-button {
	margin-left: 10px;
	width: 36px;
	height: 32px;
	color: rgba(0, 0, 0, 0.5);
}

.dataseries-add-button {
	color: rgba(0, 0, 0, 0.5);
}

.field-selection-table::-webkit-scrollbar {
	display: none;
}

.layout::-webkit-scrollbar,
.datasource-list::-webkit-scrollbar,
.field-table .ant-table-body::-webkit-scrollbar,
.schema-table .ant-table-body::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar,
.field-selection-table .ant-table-body::-webkit-scrollbar,
.preview-table .ant-table-content::-webkit-scrollbar,
.templates-table .ant-table-body::-webkit-scrollbar,
.data-source-table .ant-table-body::-webkit-scrollbar,
.data-source-table::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.layout::-webkit-scrollbar-track,
.datasource-list::-webkit-scrollbar-track,
.field-table .ant-table-body::-webkit-scrollbar-track,
.schema-table .ant-table-body::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track,
.field-selection-table .ant-table-body::-webkit-scrollbar-track,
.templates-table .ant-table-body::-webkit-scrollbar-track,
.data-source-table .ant-table-body::-webkit-scrollbar-track,
.preview-table
	.ant-table-content::-webkit-scrollbar-track
	.data-source-table::-webkit-scrollbar-track {
	background: #555;
}

.layout::-webkit-scrollbar-thumb,
.datasource-list::-webkit-scrollbar-thumb,
.field-table .ant-table-body::-webkit-scrollbar-thumb,
.schema-table .ant-table-body::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb,
.field-selection-table .ant-table-body::-webkit-scrollbar-thumb,
.templates-table .ant-table-body::-webkit-scrollbar-thumb,
.data-source-table .ant-table-body::-webkit-scrollbar-thumb,
.preview-table .ant-table-content::-webkit-scrollbar-thumb,
.data-source-table::-webkit-scrollbar-thumb {
	background-color: #dddcdc; /* Color of the thumb */
	border-radius: 6px; /* Rounded corners for the thumb */
}

.data-access-table .ant-table-thead {
	background: #f5f5f5;
	font-weight: 600;
}

.query-templates-space > div:nth-of-type(2) {
	width: inherit;
}

.query-templates-space div.ant-typography-edit-content {
	inset-inline-start: 0;
}

.dropdown_icon {
	flex: 1;
	padding-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown_title {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	/* width: 120px; */
}
.ant-menu-submenu-popup .dropdown_title {
	min-width: 120px;
}

.ant-menu.ant-menu-sub.ant-menu-inline
	.ant-menu.ant-menu-sub.ant-menu-inline
	> .ant-menu-item
	.dropdown_title {
	font-size: 14px;
}

.ant-menu-overflow-item.ant-menu-item.admin-nav-item {
	color: #000000d9;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
	background: none;
}

.ant-menu-submenu-popup {
	/* max-width: 200px; */
	min-width: 188px;
}

.wrap_menu_title {
	white-space: normal;
	/* line-height: 20px !important; */
}

.product-icon {
	height: 35px;
}

.pdf_viewer .ant-modal-close {
	top: 32px;
	inset-inline-end: 24px;
}

.sp-product-icon {
	height: 30px;
}

.white-product-icon {
	height: 35px;
	filter: brightness(0.35);
}

.selected .white-product-icon {
	filter: brightness(0.9);
}

.ant-tree .ant-tree-node-content-wrapper {
	border-radius: 2px;
	width: 100px;
}

.ant-tree.babylon-asset-tree .ant-tree-switcher {
	align-self: center;
}

.menu-tree .ant-tree-title {
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inherit;
}

.menu-manager-popconfirm .ant-popover-inner {
	background-color: #fff;
}

.menu-manager-popconfirm {
	--antd-arrow-background-color: #fff;
}

/* Home Site CSS */
.site-whole {
	height: 100%;
	background: linear-gradient(
			180deg,
			rgba(0, 42, 81, 0.7) 0%,
			rgba(1, 10, 19, 0.7) 10%
		),
		url("./assets/img/hex.png") !important;
	overflow: hidden;
}

.site-button {
	/* font-size: 200%; */
	color: white;
	margin: 10px 20px;
	display: block;
	float: right;
	width: auto;
	height: 40px;
}

.site-button-icon {
	z-index: 1;
	color: #f6f6f6;
	width: 18px;
	height: 18px;
	font-size: 18px;
}

.site-layout {
	background: rgba(0, 0, 0, 0);
}

.site-layout-background {
	background: #ededed;
}
/* .site-layout-footer-none {
	display: none;
} */
.ant-layout-footer.site-layout-footer {
	display: flex;
	padding: 0px 0px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 12px;
	background: #031220;
	min-height: 30px;
	height: 30px;
	overflow: hidden;
}

.site-layout-header {
	background: #031220;
}

.react-resizable-handle {
	filter: contrast(0.01);
}

/*** Main CSS ***/
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.admin-button {
	padding: 10px;
	margin-top: 1px;
	margin-left: 10px;
	height: 49px;
}

.add_item {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #111d2cb2;
	border: 1px solid #112a45;
}

.as-teg-wrapper {
	height: 100%;
}

.asset-tabs .ant-tabs-tab {
	padding: 0;
}

.react-grid-item.cssTransforms {
	/* border: 1px solid #112a45; */
	height: 100%;
}

.react-grid-item {
	min-width: 0;
	min-height: 0;
}

.generic-loading-wrapper {
	height: 100%;
	width: 100%;
}

.loading-wrapper,
.dt-loading-wrapper {
	display: flex;
	width: 100%;
	overflow: auto;
	flex: 1;
}

.other-loading-wrapper {
	display: flex;
	width: 100%;
}

.dt2-loading-wrapper {
	/* position: static;
	display: flex; */
	width: 100%;
	height: 100%;
}

.maximo-loading-wrapper {
	width: 100%;
}

.marketplace-content {
	overflow: hidden;
	background: linear-gradient(0deg, #111d2c, #111d2c), rgba(17, 29, 44, 0.7);
}

/* .maximo-loading-wrapper > .ant-spin-container, */
.other-loading-wrapper > .ant-spin-container,
.dt2-loading-wrapper > .ant-spin-container,
.dt-loading-wrapper > .ant-spin-container,
.loading-wrapper > .ant-spin-container {
	height: 100%;
	width: 100%;
}

.dt2-loading-wrapper .ant-spin-blur {
	opacity: 0.7;
	transition: opacity 2s;
}

.dt2-loading-wrapper .ant-spin-container {
	transition: opacity 2s;
	display: flex;
}

.flex-column-table .ant-spin-container {
	transition: opacity 2s;
	display: flex;
	flex-direction: column;
}

.flex-row-table .ant-spin-container {
	transition: opacity 2s;
	display: flex;
	flex-direction: row;
	gap: 24px;
	width: 100%;
}

.dt2-loading-wrapper .ant-table-wrapper .ant-table-thead > tr > th,
.dt2-loading-wrapper .ant-table-wrapper .ant-table-thead > tr > td {
	background: rgba(20, 20, 20, 1);
}

.dt2-loading-wrapper .ant-table-wrapper .ant-table-tbody > tr > th,
.dt2-loading-wrapper .ant-table-wrapper .ant-table-tbody > tr > td {
	background: transparent;
}

.dt2-loading-wrapper .ant-table-wrapper .ant-table-cell,
.dt2-loading-wrapper .ant-table-wrapper .ant-table-thead > tr > th,
.dt2-loading-wrapper .ant-table-wrapper .ant-table-tbody > tr > th,
.dt2-loading-wrapper .ant-table-wrapper .ant-table-tbody > tr > td,
.dt2-loading-wrapper .ant-table-wrapper tfoot > tr > th,
.dt2-loading-wrapper .ant-table-wrapper tfoot > tr > td {
	padding: 8px;
}

.dt2-loading-wrapper
	> .ant-spin-container
	> .dt-side-menu.ant-menu.ant-menu-dark {
	display: flex;
	flex-direction: column;
	margin-top: 0px;
	min-width: 74px;
	max-width: 74px;
	background: #031220;
}

.dt2-loading-wrapper
	> .ant-spin-container
	> .dt-side-menu.ant-menu.ant-menu-dark
	> .ant-menu-item {
	display: flex;
	padding: 10px 0 !important;
	min-height: 64px;
	flex-direction: column;
	align-items: center;
	height: fit-content;
}

.dt-side-menu.ant-menu-inline.ant-menu-root
	.ant-menu-item
	> .ant-menu-title-content {
	margin: 0px;
	padding: 5px;
	line-height: 1;
	text-align: center;
}

.dt-side-menu.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
	.ant-menu-item-selected {
	border-radius: 0;
	background-color: #112a45 !important;
}

.loading-wrapper > .ant-spin-container::after {
	background: transparent;
}

.document-content-header {
	height: 80px;
	background-color: rgba(17, 29, 44, 1);
	border: 1px solid #112a45;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-left: 27px;
	padding-bottom: 5px;
}

.document-content-header-title {
	font-family: "Franklin Gothic Book" !important;
	font-style: normal;
	font-size: 24px;
	line-height: 32px;
	color: rgba(255, 255, 255, 0.85);
}

.content-editor,
.content-display {
	height: 80%;
}

.content-header {
	/* height: 60px; */
	padding: 16px;
	background-color: rgba(17, 29, 44, 1);
	border: 1px solid #112a45;
	display: flex;
	align-items: center;
}

.content-header-title {
	font-family: "Franklin Gothic Book" !important;
	font-style: normal;
	margin-left: 27px;
	font-size: 20px;
	line-height: 28px;
	color: rgba(255, 255, 255, 0.85);
}

.content-tags-container {
	display: grid;
	gap: 16px;
	grid-auto-rows: max-content;
}

.create-measure-form .ant-form-item {
	margin-bottom: 12px;
}

.crew-detail-generic-font {
	line-height: 22px;
	color: rgba(255, 255, 255, 0.85);
}

.crew-detail-generic-title {
	line-height: 20px;
	font-size: 12px;
}

.document-last-modified-text-mobile {
	color: rgba(0, 0, 0, 0.45);
}

.antd-spin-blink {
	width: 100% !important;
	height: 100% !important;
	max-height: 100% !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.blinking {
	animation: blinking 5s;
	animation-iteration-count: infinite;
}

.antd-spin-loading-top {
	margin-top: 50px;
}

.v2-menu {
	width: 300px;
}

.v2-menu-submenu .ant-menu.ant-menu-sub.ant-menu-inline {
	background: #141414;
}

.v2-menu .ant-menu-item-selected,
.v2-menu-mobile .ant-menu-item-selected {
	background: #111d2c;
	color: #177ddc;
}

.v2-menu .ant-menu-item {
	margin: 0;
	border-radius: 0;
	width: auto;
}

.vessel-spin .ant-spin-dot {
	margin-top: 0px !important;
	width: 100px !important;
}

.vessel-spin .ant-spin-text {
	font-size: 1em;
	font-weight: bold;
}

.vessel-details .ant-descriptions-item-content {
	max-width: 200px;
}

.visual-card.ant-card .ant-card-head-wrapper {
	display: flex;
	gap: 12px;
}

.visual-drilldown-table .ant-table-thead tr > th {
	background: #141414;
}

.visual-drilldown-table .ant-table-body tr > td {
	background: #1f1f1f;
}

.visual-drilldown-table .ant-table-tbody-virtual-scrollbar-thumb {
	background-color: #888 !important; /* Color of the thumb */
}

.viz-body-clickable:hover {
	opacity: 0.5;
	cursor: pointer;
}

.maximo-antd-spin .ant-spin-dot {
	animation-iteration-count: infinite;
}

.antd-spin-blink .ant-spin-dot {
	animation: blinking 5s;
	animation-iteration-count: infinite;
}

.full-page-spin,
.ant-spin-container {
	height: 100%;
}

.ant-spin-nested-loading > div > .antd-spin-blink .ant-spin-dot,
.antd-spin-blink .ant-spin-dot {
	width: 30%;
	max-width: 300px;
	height: auto;
	position: relative;
	top: 0;
	left: 0;
}

.loading-wrapper > div > .antd-spin .ant-spin-text {
	font-size: 1.5em;
	font-weight: bold;
	text-shadow: none;
}

.ant-spin-nested-loading > div > .antd-spin-blink .ant-spin-text {
	position: relative;
	top: 0;
	left: 0;
	font-size: 1.5em;
	font-weight: bold;
	text-shadow: none;
}

.button-save-general {
	background: #177ddc;
	color: white;
	width: initial;
	display: flex;
	margin-left: auto;
	border: 1px solid #177ddc;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
	border-radius: 2px;
}

.button-logout {
	padding: 15px 32px;
	font-size: 30px;
}

.content-window {
	height: 100%;
	overflow: auto !important;
}

.dashboard {
	background-color: #004a93;
	position: absolute;
	width: 100%;
	height: 100%;
}

.dashboardContainer {
	position: absolute;
	width: 100%;
	background-color: lightblue;
	font-size: 100px;
	text-align: center;
}

.dashboardlogo {
	overflow: auto;
	display: block;
	width: 400px;
	height: 200px;
}

.highlight {
	color: black !important;
	background: yellow;
}

.home-footer-logo {
	align-self: center;
	margin-right: 35px;
	float: right;
	width: 125px;
	margin-top: 4px;
	height: auto;
	image-rendering: crisp-edges;
}

.home-logo {
	width: 150px;
	/* margin-left: 27px; */
}

.horizontal-form .ant-form-item .ant-row.ant-form-item-row {
	flex-direction: row;
}

.input-label {
	display: block;
	margin-bottom: 8px;
}

.large {
	font-size: 18pt;
}

.imgicon,
.large {
	background-color: #f0f0f0;
}

.layout-side-list {
	display: grid;
	gap: 30px;
	grid-template-columns: 0.5fr 3fr;
	padding-top: 1%;
}

.leaflet-container {
	width: 100%;
	z-index: 1;
	flex: 1;
	padding: 1em;
	border: 1px solid black;
}

.leaflet-container-mega {
	width: 100%;
	z-index: 1;
	flex: 1;
	padding: 1em;
	border: 1px solid black;
}

.leaflet-bottom {
	bottom: -100px;
}

.leaflet-div-icon {
	background: none;
	border: none;
}

span.ant-input-suffix {
	cursor: pointer;
}

.main {
	/* background: linear-gradient(135deg, rgb(228, 31, 31) 0%,     rgb(3, 69, 146) 100%); */
	background: linear-gradient(0deg, rgb(6 15 40 / 80%), rgb(15 30 85 / 50%)),
		url("./assets/img/hex.png");
	position: absolute;
	width: 100%;
	height: 100%;
}

.mobile-menu .ant-menu-item {
	color: #000000d9;
}

.modal-dirparameter-container {
	display: grid;
	grid-template-columns: 0.8fr 0.2fr;
}

.modal-dirparameter-content {
	display: grid;
	gap: 12px;
	grid-template-columns: 0.5fr 0.5fr;
	grid-auto-columns: auto;
	grid-auto-rows: auto auto;
	grid-template-areas:
		"a b"
		"c c";
}

.error-background,
.coming-soon-background {
	display: flex;
	justify-content: center;

	/* background: url("./assets/img/Background-RGB-05white.png") no-repeat center; */
	background-size: 100% 100%;
	height: 100%;
	width: 100%;
}

.coming-soon-center-container {
	/* position: absolute; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: -5%;
}

.error-center-container {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: -5%;
}

.hexlock-container {
	cursor: default;
	position: relative;
	width: 125px;
	height: 72.16878364870323px;
	background-color: #e6e6e6;
	margin: 36.08px auto 36.08px auto;
	box-shadow: 0 0 0px rgba(255, 255, 255, 0);
	border-left: solid 0px #ffffff;
	border-right: solid 0px #ffffff;
	display: grid;
	transition: 0s;
}

.hexlock-container:before,
.hexlock-container:after {
	content: "";
	position: absolute;
	z-index: 1;
	width: 88.38834764831843px;
	height: 88.38834764831843px;
	transform: scaleY(0.5774) rotate(-45deg);
	background-color: inherit;
	left: 18.305826175840785px;
	box-shadow: 0 0 0px rgba(255, 255, 255, 0);
	transition: 0s;
}

.hexlock-container:before {
	top: -44.194173824159215px;
	border-top: solid 0px #ffffff;
	border-right: solid 0px #ffffff;
}

.hexlock-container:after {
	bottom: -44.194173824159215px;
	border-bottom: solid 0px #ffffff;
	border-left: solid 0px #ffffff;
}

.hexlock {
	width: 100%;
	height: 100%;
	background: url("./assets/img/lock_icon.png") no-repeat center;
	background-size: contain;
	z-index: 4;
}

.hexagon-button {
	position: relative;
	width: 34px;
	height: 20px;
	background-color: #0f264a;
	margin: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hexagon-button:before,
.hexagon-button:after {
	content: "";
	position: absolute;
	width: 0;
	border-left: 17px solid transparent;
	border-right: 17px solid transparent;
}

.hexagon-button:before {
	bottom: 100%;
	border-bottom: 8px solid #0f264a;
}

.hexagon-button:after {
	top: 100%;
	width: 0;
	border-top: 8px solid #0f264a;
}

.octagon-button {
	width: 48px;
	height: 48px;
	background: #0f264a;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.octagon-button:before {
	content: "";
	width: 48px;
	height: 0;
	position: absolute;
	top: 0;
	left: 0;
	border-bottom: 13.95px solid #0f264a;
	border-left: 13.95px solid rgb(3, 18, 32);
	border-right: 13.95px solid rgb(3, 18, 32);
}

.octagon-button:after {
	content: "";
	width: 48px;
	height: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 13.95px solid #0f264a;
	border-left: 13.95px solid rgb(3, 18, 32);
	border-right: 13.95px solid rgb(3, 18, 32);
}

.marketplace_dashboard_icon_button {
	color: black;
}

.marketplace_drawer_wrapper .ant-tabs .ant-tabs-tabpane {
	height: 90% !important;
}

.marketplace_drawer_wrapper .ant-drawer-header {
	height: 60px;
}

.marketplace-drawer-footer {
	width: 100%;
	max-height: 60px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-top: auto;
}

.marketplace-dashboard-element {
	/* height: 56px; */
	width: 100%;
	/* min-height: 56px; */
	/* height: */
	height: clamp(56px, min-content, min-content);
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px 12px 16px 24px;
	background: #111d2c;
	box-shadow: inset 0px -1px 0px #112a45;
	color: white;
}

.marketplace-dashboard-element-title {
	margin: 0;
	display: block;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	font-family: "Roboto", sans-serif !important;
}

.marketplace-dashboard-element-subtitle {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	font-family: "Roboto", sans-serif !important;
	color: rgba(255, 255, 255, 0.45);
}

.marketplace-dashboard-preview-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border: 1px solid #112a45;
}

.marketplace-dashboard-preview-element {
	height: 56px;
	width: auto;
	min-height: 56px;
	display: flex;
	align-items: center;
	background: #111d2c;
	box-shadow: inset 0px -1px 0px #112a45;
	color: white;
	padding-left: 16px;
}

.marketplace-dashboard-preview-element-title {
	margin: 0;
	margin-left: 24px;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	font-family: "Roboto", sans-serif !important;
}

.marketplace-dashboard-preview-element-subtitle {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	font-family: "Roboto", sans-serif !important;
	color: rgba(255, 255, 255, 0.45);
}

.marketplace-dashboard-icon-button {
	background-color: transparent;
	color: #1765ad;
	border: none;
	box-shadow: none;
}

.marketplace-dashboard-icon-button:hover {
	border: none !important;
	box-shadow: none;
}

.marketplace-dashboard-icon-button:focus {
	border: none !important;
	box-shadow: none;
}

.marketplace-dashboard-settings-button {
	color: black;
}

.marketplace-dashboard-element-dropdown .ant-dropdown-menu {
	padding: 0;
}

/* .mega-menu-overlay {
	background: linear-gradient(
		180deg,
		rgba(0, 42, 81, 0.3) 0%,
		rgba(1, 10, 19, 0.3) 100%
	);
	/* background-blend-mode: multiply; */
/* } */
.mega-menu-parent {
	padding: 0;
}

.mega-menu-parent::-webkit-scrollbar {
	display: none;
}

.mega-menu {
	/* background: url("./assets/img/hex.png"),
		linear-gradient(180deg, rgba(0, 42, 81, 0.6) 0%, rgba(1, 10, 19, 0.6) 20%) !important; */
	/* background: linear-gradient(
			180deg,
			rgba(0, 42, 81, 0.6) 0%,
			rgba(0, 42, 81, 0.6) 100%
			),
			url("./assets/img/hex.png") !important
			; */
	/* background-blend-mode: multiply; */
	/* padding: 48px; */
	background: linear-gradient(
			180deg,
			rgba(17, 29, 44, 0.75) 0%,
			rgba(17, 29, 44, 0.75) 100%
		),
		url("./assets/img/hex.png") !important;
}

.mega-menu-item {
	background: none;
	border: none !important;
}

.mega-menu-text-disabled {
	color: rgba(255, 255, 255, 0.5);
}

.mega-menu-group-label,
.mega-menu-top-item {
	font-size: 16px;
}

.mega-menu-top-level {
	display: flex;
	/* width: -webkit-fill-available; */
	align-items: center;
}

.mega-menu-top-level .ant-typography {
	white-space: nowrap;
}

.mega-menu-side-menu {
	height: 100%;
	background: rgba(0, 0, 0, 0.55);
}

.mega-menu-v2-item {
	height: 40px;
	padding: 4px 24px;
	display: flex;
	align-items: center;
}

.mega-menu-v2-item:not(:disabled) {
	text-align: start;
	border-radius: 4px;
	border: 1px solid #262626;
	background: #1f1f1f;
	box-shadow: none;
}

.mega-menu-v2-item:not(:disabled):hover {
	border: 1px solid #15395b;
	background: #112a45;
	color: #177ddc;
}

.mega-menu-v2-item-active:not(:disabled) {
	/* border: 1px solid #15395b;
	background: #112a45;
	color: #177ddc; */
	border: 1px solid #15395b;
	background: #111d2c;
	color: #177ddc;
}

.package-title-container {
	height: 40px;
	width: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 16px;
	border-width: 1px;
	border-color: rgb(17, 42, 69);
	border-style: solid;
	background: rgba(17, 29, 44, 0.7);
}

.package-content-container {
	height: calc(100% - 42px);
	border-top: none;
	border-right: 1px solid rgb(17, 42, 69);
	border-bottom: 1px solid rgb(17, 42, 69);
	border-left: 1px solid rgb(17, 42, 69);
	background: rgba(17, 29, 44, 0.7);
	display: block;
}

.query-params-label {
	justify-self: end;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-bottomLeft
	.ant-menu-submenu-title {
	padding-left: 16px !important;
	margin-left: 0;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mega-menu-sidebar
	.ant-menu-item-group {
	display: block;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mega-menu-sidebar
	.ant-menu-item-group-title,
.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mega-menu-sidebar
	.ant-menu-item.ant-menu-item-only-child {
	padding-left: 0;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mega-menu-sidebar
	.ant-menu-item-group-title {
	font-size: 16px;
	transition: none;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.mega-menu-sidebar
	.ant-menu-item.ant-menu-item-only-child
	.ant-menu-title-content {
	font-size: 14px;
}

.ant-menu-item-group-title .ant-typography.mega-menu-group-label .anticon {
	height: 32px;
	font-size: 28px;
}

.mega-menu-sidebar-wrapper .ant-menu-item-group-title {
	cursor: default;
}

/* .ant-menu-item-group {
	height: 48px;
	margin: 4px 0;
	display: flex;
	align-items: center;
} */

.menu {
	grid-area: menu;
	background-color: darkblue;
	color: white;
}

.menu-img {
	align-self: center;
	/* min-width: inherit !important; */
	/* width: 30px; */
}

.menu-img-sp {
	align-self: center;
	/* min-width: inherit !important; */
	width: 30px;
}

.mega-menu-wrapper .mega-menu-item .ant-menu-item .anticon {
	min-width: 18px;
}

.mega-menu-item .anticon svg,
.ant-menu-item-group-title .anticon svg {
	height: 30px;
	width: 30px;
	font-size: 30px;
}

.mega-menu-item .anticon .nodrag {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

.noselect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.dashboard_wrapper {
	background: url("./assets/img/Portal_DarkBackground.png") no-repeat center;
	background-size: cover;
}

.document-sub-title {
	font-size: 1.5em;
	font-weight: bold;
}

.document-table {
	width: 100%;
	flex: 1;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 0px 4px rgb(217, 217, 217);
	margin-top: 20px;
}

.document-table-header {
	display: flex;
	border-bottom: 1px solid #f0f0f0;
	align-items: center;
	padding: 1% 15px;
}

.document-table-row {
	cursor: pointer;
}

.document-main-container {
	min-height: 85%;
	height: auto;
	background-color: white;
	box-shadow: 0px 0px 4px rgb(217, 217, 217);
	display: grid;
	grid-template-columns: 0.65fr 0.35fr;
	grid-template-rows: 0.05fr 0.95fr;
	padding: 20px 0;
}

.document-main-container-mobile {
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0px 0px 4px rgb(217, 217, 217);
	padding: 20px 0;
}

.edit-mode-disabled-popover {
	width: 200px;
}

.folder-tag-layout-properties {
	display: grid;
	grid-template-columns: 1fr 0.3fr 1fr 0.3fr;
}

.folder-title {
	font-weight: 600;
	font-size: 14px;
	vertical-align: middle;
}

.folder-top-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.folder-bottom-container {
	display: grid;
	row-gap: 20px;
	column-gap: 20px;
	grid-template-columns: 0.5fr 0.5fr;
	grid-auto-rows: minmax(min-content, max-content);
}

.folder {
	border: solid rgb(240, 240, 240) thin;
	display: grid;
	/* grid-template-rows: 0.25fr 1fr 0.1fr; */
	/* margin-bottom: 5%; */
}

.folder-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2% 5%;
	height: 100%;
	border-bottom: solid rgb(240, 240, 240) thin;
}

.folder-body {
	padding: 2% 5%;
	height: 100%;
}

.folder-body div {
	padding: 2% 0;
}

.folder-footer {
	display: grid;
	height: 100%;
	border-top: solid rgb(240, 240, 240) thin;
	grid-template-columns: repeat(4, 0.25fr);
}

.folder-buttons {
	color: rgb(140, 140, 140);
	border: none;
	width: 100%;
	/* border-right: 1px solid rgb(240, 240, 240); */
}

.ant-btn-default:not(:disabled).folder-buttons:hover {
	border-color: rgb(240, 240, 240);
	outline: none !important;
}

.folder-buttons:focus {
	border-color: rgb(240, 240, 240);
	outline: none !important;
	color: rgb(140, 140, 140);
}

.folder-buttons:last-child {
	border: none;
}

.document-container,
.markdown-container {
	width: 100%;
	height: calc(100vh - 94px);
	background: #fafafb;
	padding: 2% 3%;
	overflow-y: auto;
}

.document-container-mobile,
.markdown-container-mobile {
	width: 100%;
	background: #fafafb;
}

.markdown-container-mobile {
	overflow-y: auto;
}

.markdown-container-mobile input {
	border: none;
}

.markdown-top-container {
	display: grid;
	grid-template-columns: 0.75fr 0.25fr;
	margin-bottom: 1%;
}

.markdown-title-container {
	display: flex;
	align-content: center;
	flex-direction: column;
	justify-content: center;
}

.logincontainer p,
.markdown-title-container p,
.markdown-bottom-container p,
.markdown-bottom-container h2 {
	margin-top: 0;
}

.markdown-bottom-container p {
	line-height: 1.5715;
}

.markdown-bottom-container h2 {
	margin-bottom: 0.5em;
}

.markdown-title {
	font-size: 35px;
	font-weight: 600;
}

.markdown-contact-container {
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding-left: 10px;
	line-height: 2.5;
	background: white;
}

.markdown-bottom-container {
	background-color: white;
	display: grid;
	grid-template-columns: 0.6fr 0.4fr;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding-top: 4%;
}

.markdown-bottom-container-mobile {
	background-color: white;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding-top: 4%;
	width: 100%;
	overflow-x: auto;
}

.markdown-notes {
	background: white;
	margin-left: auto;
	margin-right: auto;
	padding-left: 5%;
	padding-right: 5%;
	width: 100%;
	/* Need a specific value to work */
	height: auto;
	overflow: auto;
}

.markdown-notes h1 {
	font-size: 2em;
}

.markdown-notes h1:first-child {
	margin-top: 0;
}

.markdown-notes h2 {
	font-weight: 500;
}

.markdown-table-of-contents {
	line-height: 2;
	padding-left: 5%;
}

.markdown-content-mobile {
	line-height: 2;
	padding: 0px 5% 10% 5%;
}

.md-link {
	color: rgb(131, 195, 255);
}

.report-style-class {
	height: 100%;
	width: 100%;
}

.selected {
	/* background: black; */
	color: white;
}

.vessel-button {
	height: 100%;
	border: 0px;
	background-color: white;
}

.vessel-button:hover {
	cursor: pointer;
	background-color: aliceblue;
}

.vessel-button-selected {
	height: 100%;
	border: 0px;
	background-color: #ffdfde;
}

.vessel-list-container {
	height: 115px;
	background-color: white;
	align-items: center;
	overflow-x: auto !important;
	overflow-y: hidden !important;
	display: flex;
	/* margin: auto; */
	margin: 0;
}

.vessel-list-container::-webkit-scrollbar {
	height: 10px;
}

.vessel-list-container::-webkit-scrollbar-thumb {
	background-color: #0049935d;
	outline: 1px solid rgba(200, 200, 200, 0.2);
}

.vessel_drawer_wrapper .ant-drawer-body::-webkit-scrollbar {
	width: 10px;
}

.vessel_drawer_wrapper .ant-drawer-body::-webkit-scrollbar-thumb {
	background-color: #0049935d;
	outline: 1px solid rgba(200, 200, 200, 0.2);
}

.notification-counter {
	background: red;
	height: 15px;
	width: 15px;
	font-size: 10px;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	bottom: 95%;
	left: 58%;
}

.notification-window {
	min-height: 140px;
	max-width: 350px;
	max-height: 350px;
	overflow-y: auto;
}

.notification-mobile {
	width: 210px;
}

/* .ant-menu-vertical .ant-menu-item:not(:last-child) {
	margin-bottom: 0px;
} */

.ant-menu.ant-menu-sub.ant-menu-vertical
	.ant-menu-item.ant-menu-item-only-child {
	/* padding: 0 !important; */
	padding-right: 0;
}

.mega-menu-wrapper {
	pointer-events: none;
}

.mega-menu-sidebar .ant-menu-item,
.mega-menu-item .ant-menu-item,
.mega-menu-item .ant-menu-item {
	pointer-events: auto;
}

.notification-item {
	background-color: rgb(38, 61, 38);
	color: white;
	font-weight: bold;
	padding: 5px;
	margin: 5px;
	display: grid;
	grid-template-columns: 1fr 5fr;
}

.notification-item > div {
	padding-left: 5px;
}

.notification-large {
	font-size: 20px;
}

.alert-message-box-top {
	position: absolute !important;
	top: 30px;
	left: calc(100vw - 65%);
	width: 35%;
	z-index: 30000;
}

.alert-message-box {
	position: absolute !important;
	bottom: 30px;
	left: 1%;
	margin: auto;
	width: 35%;
	z-index: 30000;
}

.alert-message-box-mobile {
	position: absolute !important;
	bottom: 30px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	z-index: 30000;
}

.notification-modal {
	position: absolute;
	top: 60px;
	right: 100px;
	box-shadow: 0px 0px 5px 2px rgb(155, 155, 155);
	padding: 0px;
}

.notification-modal .ant-modal-content {
	padding: 0;
}

.user-menu {
	background-color: white !important;
}

.user-menu:hover {
	border-right: 3px solid #177ddc;
	box-sizing: border-box;
	color: #177ddc;
	transition: none;
	background-color: #177ddc33 !important;
}

.unclickable {
	pointer-events: none;
}

.UserSubmission-modal {
	/* position: absolute; */
	/* top: 50px; */
	/* right: 180px; */
	/* box-shadow: 0px 0px 5px 2px rgb(155, 155, 155); */
	padding: 0px;
	min-width: 50%;
	max-width: 1000px;
	width: auto !important;
	border-radius: 2px;
}

.notification-wrapper {
	z-index: 10000 !important;
}

/* config plot modal */
.config-plot-modal-date-picker {
	border-radius: 2px;
}

.config-plot-modal-date-picker-popup {
	z-index: 30003;
}

.config-plot-modal-date-radio-group,
.config-plot-modal-type-radio-group {
	display: flex;
}

.config-plot-modal-date-radio-group .ant-radio-button-wrapper,
.config-plot-modal-type-radio-group .ant-radio-button-wrapper {
	min-width: fit-content;
	width: 100%;
	text-align: center;
}

.config-plot-modal-date-radio-group label,
.config-plot-modal-date-radio-group span,
.config-plot-modal-type-radio-group label,
.config-plot-modal-type-radio-group span {
	border-radius: 2px !important;
}

.config-plot-modal-data-series-btns {
	/* margin-left: 10px; */
	/* width: 36px; */
	/* height: 32px; */
	color: rgba(0, 0, 0, 0.5);
}

.configuration-modal-body,
.configuration-modal-date-inputs,
.configuration-modal-inputs {
	display: grid;
	align-items: start;
	gap: 20px;
}

.configuration-modal-date-radiogroup,
.configuration-modal-legend-radiogroup {
	display: flex;
}

.configuration-modal-date-radiobutton,
.configuration-modal-legend-radiobutton {
	width: 100%;
	text-align: center;
}

.configure-plot-modal {
	min-width: 50%;
	width: auto !important;
}

.configure-plot-modal .ant-modal-footer {
	display: flex;
	justify-content: flex-end;
}

.marketplace_drawer_wrapper {
	top: 64px;
	bottom: initial;
	left: initial;
	height: calc(100% - 94px);
}

.notification-nav-bar {
	background-color: #ffffff;
	line-height: 40px;
	color: black;
	font-size: 14px;
	text-align: center;
	margin-top: 1px;
}

.notification-menu-item {
	cursor: pointer;
	color: black;
	background-color: white;
	top: 0px !important;
}

.notification-nav-bar > .ant-menu-item-selected {
	color: #1890ff !important;
	background-color: white !important;
	border-bottom: solid 3px;
}

.notification-items {
	display: grid;
	grid-template-columns: 0.2fr 0.8fr;
	border-bottom: solid 1px #f4f4f4;
	max-height: 175px;
	padding: 10px 0px;
}

.notification-content-container {
	margin-right: 20px;
	max-height: 175px;
}

.notification-icon {
	display: flex;
	justify-content: center;
}

.notification-new-release {
	width: 50%;
	height: 30px;
	background: #f3b730;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notification-content {
	max-height: 100px;
	overflow: hidden;
	margin: 5px 0px;
	color: grey;
	text-align: left;
}

.notification-date {
	color: grey;
	display: flex;
	align-self: center;
	margin-left: auto;
	font-size: 12px;
}

iframe {
	border: none;
}

.div-select-horizontal {
	display: flex;
	align-items: center;
	flex-direction: row;
}

.table-actions {
	text-decoration: underline;
	color: #1890ff;
}

.tag-item {
	display: flex;
	justify-content: space-between;
}

.tag-list-buttons {
	display: flex;
	gap: 4px;
}

.theme-switch .ant-switch {
	background: #3d3c3c !important;
}

.theme-switch .ant-switch-checked {
	background: #1890ff !important;
}

.titillium-web-text {
	font-family: "Titillium Web";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 26px;
}

.leaderboard-vl {
	border-left: white solid 1px;
	display: flex;
	align-self: center;
	position: absolute;
	height: 655px;
	margin-top: 30px;
}

.open-side-panel {
	right: 500px;
}

.close-side-panel {
	right: 0px;
}

.clean-result .ant-result-title {
	font-size: 14px;
	color: #ffffffa6;
}

.clean-result .ant-result-icon .anticon {
	font-size: 42px;
	color: #177ddc;
}

.clean-result {
	border: 1px solid #434343;
}

.open-bottom-panel {
	bottom: 500px;
}

.close-bottom-panel {
	bottom: 0px;
}

.panel {
	transition: all 0.33s;
}

.param-table-dropdown {
	height: auto;
}

/* Babylon Inspector CSS */
#actionTabs .tabsMenu .labels .label {
	padding: 0 !important;
	height: auto !important;
}

#actionTabs .tabsMenu .panes .pane .buttonLine {
	height: fit-content !important;
}

#actionTabs .tabsMenu .panes .pane .paneContainer {
	margin-top: 0 !important;
}

#actionTabs .tabsMenu .panes .pane .floatLine {
	height: 40px !important;
}

.viz-header.ant-card-head {
	padding: 12px 16px;
}

.ant-typography.custom-statistic-value {
	font-size: 32px;
}

.custom-statistics-wrapper {
	padding: 24px;
}

@media (max-width: 996px) {
	.ant-typography.custom-statistic-value {
		font-size: 28px;
	}

	.ant-card-head-title {
		font-size: 14px;
	}

	.viz-header.ant-card-head {
		padding: 10px 14px;
		min-height: 48px;
	}

	.custom-statistics-wrapper {
		padding: 20px;
	}
}

@media (max-width: 768px) {
	.ant-typography.custom-statistic-value {
		font-size: 24px;
	}

	.ant-card-head-title {
		font-size: 13px;
	}

	.viz-header.ant-card-head {
		padding: 8px 12px;
		min-height: 40px;
	}

	.custom-statistics-wrapper {
		padding: 16px;
	}
}

@media only screen and (max-width: 480px) {
	.ant-typography.custom-statistic-value {
		font-size: 20px;
	}

	.ant-card-head-title {
		font-size: 12px;
	}

	.viz-header.ant-card-head {
		padding: 6px 10px;
		min-height: 32px;
	}

	.custom-statistics-wrapper {
		padding: 12px;
	}
}

@media (max-width: 320px) {
	.ant-typography.custom-statistic-value {
		font-size: 18px;
	}

	.ant-card-head-title {
		font-size: 10px;
	}

	.viz-header.ant-card-head {
		padding: 4px 8px;
		min-height: 28px;
	}

	.custom-statistics-wrapper {
		padding: 8px;
	}
}
