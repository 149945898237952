.admin-container .ant-input-search-button svg {
	color: rgba(0, 0, 0, 0.45);
}

.admin-container .ant-select-disabled .ant-select-selector {
	border: 1px solid #d9d9d9;
}

.admin-menu-configuration {
	z-index: 1;
	background-color: rgb(250, 250, 251);
	/* overflow: auto; */
	margin-bottom: 50px;
}

.admin-nav-bar {
	background-color: #ffffff;
	color: black;
	box-shadow: 0px 1px 4px 1px rgb(217 217 217);
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	height: 47px;
}

.admin-nav-bar-container {
	background-color: #ffffff;
}

.admin-nav-item {
	border-right: 1px solid rgba(150, 125, 125, 0.3);
	width: 200px;
	display: flex !important;
	justify-content: center;
}

.admin-table-title {
	height: 5%;
	background-color: #ffffff;
	padding: 1%;
	padding-left: 4%;
	font-size: 20px;
	color: rgb(5, 5, 5);
	font-weight: 400;
}

.admin-table {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 0px 4px rgb(217, 217, 217);
	margin-bottom: 30px;
}

.admin-title-container {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	padding: 2% 0px;
}

/* .admin-title {
	font-size: 35px;
} */

.sensor-mapping-select :not(.ant-select-customize-input).ant-select-selector,
.menu-manager-select :not(.ant-select-customize-input).ant-select-selector {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	color: rgba(0, 0, 0, 0.88);
}

.sensor-mapping-select .ant-select-selection-placeholder {
	color: rgba(0, 0, 0, 0.25);
}

.sensor-mapping-select-popup.ant-select-dropdown,
.menu-manager-select-popup.ant-select-dropdown {
	background-color: #fff;
}

.sensor-mapping-select-popup.ant-select-dropdown .ant-select-item {
	color: rgba(0, 0, 0, 0.88);
}

.sensor-mapping-select-popup.ant-select-dropdown
	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: rgba(0, 0, 0, 0.04);
	font-weight: normal;
}

.button-general {
	background-color: rgb(26, 53, 86);
	color: white;
	font-weight: 500;
}

.button-general:focus {
	background-color: rgb(26, 53, 86);
	color: white;
}

.cancel {
	background-color: #e4e5e9;
	color: black;
}

.command-options-bar > button {
	font-weight: bold;
}

.command-options-bar {
	text-align: center;
}

.confirm {
	background-color: #1a3556;
	color: white;
}

.edit:hover {
	cursor: pointer;
}

.edit {
	color: blue;
	text-decoration: underline;
}

.input {
	width: 100%;
}

.admin-console {
	width: 100%;
}

.para-input {
	min-height: 100px;
	height: auto;
	width: 100%;
}

.admin-menu-button {
	margin-bottom: 3%;
}

.site-tag-plus {
	background: #fff;
	border-style: dashed;
	cursor: pointer;
}

.tag-select {
	width: 150px;
	height: 32px;
	margin-right: 8px;
	vertical-align: top;
}

.para-select {
	width: 100%;
}

.layout-properties {
	display: grid;
	grid-template-columns: 1fr 2fr 0.3fr;
}

.menu-tree {
	margin-bottom: 50px;
}

.admin-layout-properties {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 0.3fr;
}
