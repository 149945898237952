.ant-spin-nested-loading .ant-spin.antd-spin-loading-mobile {
	position: fixed;
	top: 50%;
	min-width: 100vw;
	height: unset !important;
	max-height: none;
	width: unset;
}

.ant-spin-nested-loading
	> div
	> .antd-spin-blink.ant-spin.antd-spin-loading-mobile
	.ant-spin-text {
	font-size: 14px;
}
