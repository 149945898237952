.button-login {
	background-color: #e9eaef;
	color: black;
	width: 100%;
	font-size: 16px;
	height: fit-content;
	font-weight: 400;
}

.button-login-normal {
	background-color: #1a3556;
	font-weight: 400;
	color: white;
	width: 100%;
	font-size: 16px;
	height: fit-content;
}

.container {
	height: 100%;
	display: grid;
	grid-template-columns: 0.7fr 0.3fr;
}

.container-center {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.display-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.company-logo {
	display: block;
	image-rendering: optimizeQuality;
}

.footer-login {
	width: 70%;
	padding: 0px 50px;
	text-align: right;
	background-color: rgba(8, 7, 7, 0);
	position: absolute;
	bottom: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	display: flex;
	align-items: center;
}

.footer-logo {
	align-self: center;
	margin-right: 35px;
	float: right;
	width: 125px;
	margin-top: 4px;
	height: auto;
	image-rendering: crisp-edges;
}

.footer-text {
	color: rgba(255, 255, 255, 0.3);
	float: left;
	white-space: pre;
}

.left-container {
	background: linear-gradient(0deg, rgb(6 15 40 / 80%), rgb(15 30 85 / 50%)),
		url("../img/hex.png");
	box-shadow: inset 0 0 150px 50px rgb(0, 0, 0);
}

.login {
	display: flex;
	background-color: #ffffff;
	padding: 10%;
	align-content: center;
	justify-content: center;
	flex-direction: column;
}

.login-logo {
	object-fit: fill;
}

.loginBody {
	height: 100%;
	/* background: linear-gradient(135deg, rgb(228, 31, 31) 0%,     rgb(3, 69, 146) 100%); */
}

.logincontainer {
	text-align: center;
}

.portal-login-logo {
	image-rendering: optimizeQuality;
	margin-left: auto;
	margin-right: auto;
	width: 350px;
	height: 150px;
}

.slogan {
	color: rgba(255, 255, 255, 0.8);
	transform: translate(5%, -80%);
}

.slogan-mobile {
	color: rgba(255, 255, 255, 0.8);
	/* transform: translate(27%, -30%); */
	/* margin-left: 27%; */
	align-self: center;
	margin-top: -10px;
}

/*@media screen and (max-width: 760px) and (orientation:portrait),
screen and (max-width: 1024px) and (max-height:760px) and (orientation:landscape) {*/

.mLoginBody {
	height: 100%;
	/* background: linear-gradient(135deg, rgb(228, 31, 31) 0%,     rgb(3, 69, 146) 100%); */
}

.mcontainer {
	height: 100%;
	display: block;
	/* background: linear-gradient(0deg, rgb(6 15 40 / 80%), rgb(15 30 85 / 50%)),
		url("../img/hex.png"); */
}

.mcontainer-center {
	width: 100%;
	/* display: flex;
	flex-direction: column; */
	align-items: center;
	height: 100%;
}

.mdisplay-container {
	padding-top: 15%;
	padding-bottom: 30%;
	height: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	background: linear-gradient(0deg, rgb(6 15 40 / 80%), rgb(15 30 85 / 50%)),
		url("../img/hex.png");
	/* background: rgba(0, 0, 0, 0); */
}

.mfooter-login {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 10px 5px;
	/* text-align: right; */
	/* line-height: 1px; */
	background-color: #000;
	position: fixed;
	bottom: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 12px;
}

.mportal-login-logo {
	image-rendering: optimizeQuality;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.mlogin {
	/* display: flex; */
	background-color: #ffffff;
	border-radius: 0px;
	/* margin-top: 20px; */
	padding: 20% 10%;
	/* align-content: center; */
	/* justify-content: center; */
	/* flex-direction: column; */
	/* position: absolute;
	bottom: 0; */
	width: 100%;
}

.mlogincontainer {
	text-align: center;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2),
		0px 6px 20px 0px rgba(0, 0, 0, 0.19);
	background-color: #fff;
	padding: 10%;
	margin-top: -50%;
	margin-bottom: 5%;
}

.mcompany-logo {
	display: block;
	image-rendering: optimizeQuality;
	margin-left: auto;
	margin-right: auto;
}
