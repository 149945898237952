.content-display,
.ql-editor {
	overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: #ccc #fff;
}

/* For webkit browsers */
.ql-editor::-webkit-scrollbar,
.content-display::-webkit-scrollbar {
	width: 5px;
}

.ql-editor::-webkit-scrollbar-track,
.content-display::-webkit-scrollbar-track {
	background: #444;
}

.ql-editor::-webkit-scrollbar-thumb,
.content-display::-webkit-scrollbar-thumb {
	background-color: #a7a7a7;
	border-radius: 10px;
}
