.custom_dashboard_layout .custom_dashboard_layout_header {
	padding: 16px;
	gap: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #112a45;
	border-top: none;
}

.custom_dashboard_layout .custom_dashboard_layout_header_mobile {
	padding: 16px;
	gap: 8px;
	display: flex;
	align-items: center;
	border: 1px solid #112a45;
	border-top: none;
	flex-wrap: wrap;
	height: auto;
}
