* {
	font-family: "Titillium Web", sans-serif;
}

body {
	margin: 0;
	height: auto;
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

iframe#launcher {
	bottom: 31px !important;
}

iframe[title="Messaging window"] {
	transform: translateY(-7px) !important;
}
