.dark-mode {
	color-scheme: black;
}

.dark-mode a:hover {
	color: #40a9ff;
}

.dark-mode .ant-tree {
	color: #000000d9;
}

.dark-mode .ant-menu-overflow-item.ant-menu-item.admin-nav-item {
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .admin-div .ant-btn-default:not(:disabled):hover {
	background: #fff;
}

.dark-mode .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background: #bae7ff;
	border-radius: 2px;
}

.dark-mode
	.ant-tree.digital-twin-asset-tree
	.ant-tree-node-content-wrapper.ant-tree-node-selected {
	background: #303c43;
	border-radius: 2px;
}

.dark-mode .tag-select .ant-input {
	background: #1f1f1f;
}

.dark-mode .tag-item a {
	color: rgba(255, 255, 255, 0.25);
}

.dark-mode
	.ant-select.dashboard-select.ant-select-single.ant-select-show-arrow.ant-select-show-search
	.ant-select-selector {
	background: #141414;
	color: #ffffff9d;
	border: 1px solid #434343;
}

.dark-mode .site-tag-plus {
	color: #000000d9;
	border: 1px dashed #d9d9d9;
}

.dark-mode .ant-dropdown .ant-dropdown-menu {
	padding-left: 0;
	padding-right: 0;
}

.dark-mode .ant-btn.ant-btn-default.button-general {
	color: #fff;
}

.dark-mode .document-search .ant-input-affix-wrapper:hover {
	border-color: #3c89e8;
}

.dark-mode .documents-textarea {
	background-color: #141414;
	border-color: #424242;
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .ant-btn.ant-btn-default.ant-btn-icon-only.folder-buttons .anticon {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #8c8c8c;
	transform: none;
}

.dark-mode
	.ant-btn.ant-btn-default.ant-btn-icon-only.folder-buttons
	.ant-btn-icon {
	width: 100%;
	border-right: 1px solid rgb(240, 240, 240);
}
.dark-mode
	.ant-btn.ant-btn-default.ant-btn-icon-only.folder-buttons:last-child
	.ant-btn-icon {
	border-right: none;
}

.dark-mode
	.ant-btn.ant-btn-default.ant-btn-icon-only.folder-buttons:not(
		:disabled
	):hover {
	background-color: #111d2c;
	color: #177ddc;
	border: 1px solid #1765ad;
}

.dark-mode .admin-menu-button {
	color: #ffffffd9;
}

.dark-mode .ant-table-pagination .ant-pagination-item,
.dark-mode .ant-table-pagination li {
	border: none;
}

.dark-mode .ant-pagination .ant-pagination-item-link:disabled:hover {
	background: #ffffff1f;
}

.dark-mode
	.ant-pagination
	.ant-pagination-item:not(.ant-pagination-item-active):hover {
	background: rgba(255, 255, 255, 0.12);
}

.dark-mode
	.ant-btn.ant-btn-default.marketplace-dashboard-settings-button.stopFocus {
	background: #031220;
}

.dark-mode .ant-btn.ant-btn-default.ant-btn-icon-only.drawer-button-close {
	background: none;
}

.dark-mode .ant-menu-overflow-item.ant-menu-item .ant-menu-title-content {
	border-bottom: solid 3px transparent;
}

.dark-mode .ant-pagination .ant-pagination-item {
	border-radius: 2px;
	border: 1px solid #343434;
}

.dark-mode .ant-menu-overflow-item.ant-menu-item.notification-menu-item {
	border-bottom: none;
}

.dark-mode .ant-modal-body .site-tag-plus,
.dark-mode .ant-modal-body .ant-tag.role-tags {
	background: #141414;
	color: rgba(255, 255, 255, 0.85);
	border-color: #434343;
	border-style: dashed;
	cursor: pointer;
}

.dark-mode .ant-modal-body .ant-tag.role-tags .ant-tag-close-icon {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .ant-modal-body .ant-tree {
	background: transparent;
	color: rgba(255, 255, 255, 0.85);
}
.dark-mode .ant-modal-body .ant-tree-treenode:hover::before {
	background: #303030 !important;
	color: rgba(255, 255, 255, 0.85);
}
.dark-mode .ant-modal-body .ant-tree-treenode-selected::before {
	background: #141414 !important;
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .admin-menu-button {
	background: #141414;
	border: 1px solid #434343;
	border-radius: 2px;
}

.dark-mode .admin-menu-button:hover {
	color: #1765ad;
	border: 1px solid #1765ad;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	cursor: pointer;
}

.dark-mode .admin-nav-bar {
	background-color: rgba(17, 29, 44, 1);
	color: rgba(255, 255, 255, 0.65);
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.dark-mode .admin-nav-item {
	border-right: none;
}

.dark-mode .admin-nav-item:hover {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */
}

.dark-mode .ant-menu-horizontal > .ant-menu-item-selected {
	background-color: transparent !important;
	color: #177ddc !important;
}

.dark-mode
	.ant-menu-horizontal
	> .ant-menu-item-selected
	.ant-menu-title-content {
	color: rgba(23, 125, 220, 1);
}

.dark-mode .admin-nav-item:hover .ant-menu-title-content,
.dark-mode .admin-nav-item:focus .ant-menu-title-content {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */
	border-bottom: 2px solid #177ddc;
	height: 46px;
	color: #177ddc !important;
	background-color: transparent !important;
}

.dark-mode .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active {
	background-color: transparent;
}

.dark-mode .ant-alert-message,
.dark-mode .ant-alert-description {
	color: rgba(255, 255, 255, 0.85) !important;
}

.dark-mode .ant-alert-error {
	background-color: #2a1215;
	border: 1px solid #58181c;
}

.dark-mode .ant-alert-error .ant-alert-icon {
	color: #a61d24;
}

.dark-mode .ant-alert-info {
	background-color: #111b26;
	border: 1px solid #153450;
}

.dark-mode .ant-alert-info .ant-alert-icon {
	color: #177ddc;
}

.dark-mode .ant-alert-success {
	background-color: #162312;
	border: 1px solid #274916;
}

.dark-mode .ant-alert-success .ant-alert-icon {
	color: #49aa19;
}

.dark-mode .ant-alert-warning {
	background-color: #2b2111;
	border: 1px solid #594214;
}

.dark-mode .ant-alert-warning .ant-alert-icon {
	color: #d89614;
}

.dark-mode .ant-alert-close-icon .anticon-close {
	color: rgba(255, 255, 255, 0.45);
	transition: color 0.3s;
}

.dark-mode .ant-alert-close-icon .anticon-close:hover {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .ant-breadcrumb,
.dark-mode .ant-breadcrumb .ant-breadcrumb-separator,
.dark-mode .ant-breadcrumb .ant-breadcrumb-link,
.dark-mode .ant-breadcrumb a {
	color: rgba(255, 255, 255, 0.45);
}

.dark-mode .ant-breadcrumb a:hover,
.dark-mode .ant-breadcrumb .link:hover {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .ant-modal-footer .ant-btn-primary:not(:disabled) {
	background: #177ddc;
	border: 1px solid #177ddc;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
	border-radius: 2px;
	color: #ffffff;
}

.dark-mode .ant-modal-footer .ant-btn-primary:not(:disabled):hover {
	border-color: #1765ad;
	background: #1765ad;
	color: #fff;
}

.dark-mode .ant-dropdown-menu {
	background: #141414;
}

.dark-mode .ant-layout-content {
	background: url("../img/Portal_DarkBackground.png") no-repeat center;
	background-size: cover;
	/* color: rgba(255, 255, 255, 0.85); */
}

.dark-mode .ant-modal {
	/* box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
		0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05); */
	border-radius: 2px;
}

.dark-mode .babylon-tree {
	color: white;
}
.dark-mode .babylon-tree .ant-tree-treenode {
	white-space: nowrap;
}

.dark-mode .button-general {
	background: rgb(20, 20, 20);
	border: 1px solid #434343;
	border-radius: 2px;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
	font-family: "Franklin Gothic Medium";
	font-size: 14px;
	line-height: 22px;
	width: inherit;
}

.dark-mode .ant-modal-body .button-general:disabled,
.dark-mode .button-general:disabled {
	border-color: #424242 !important;
	color: rgba(255, 255, 255, 0.25) !important;
	background-color: rgba(255, 255, 255, 0.08) !important;
	box-shadow: none;
}

.dark-mode .button-save-and-close-general:enabled {
	color: #ffffffd9;
	background: #141414;
	border: 1px solid #434343;
}

.dark-mode .button-save-and-close-general:enabled:hover {
	color: #1765ad;
	border-color: #1765ad;
}

.dark-mode .button-delete-general:hover {
	color: #1765ad;
	border: 1px solid #1765ad !important;
}

.dark-mode .subtext {
	color: rgba(255, 255, 255, 0.45);
}

.dark-mode .ant-modal-body .ant-radio-group .span.ant-radio + *,
.dark-mode .ant-modal-body .ant-radio-group,
.dark-mode .ant-modal-body .ant-radio-wrapper,
.dark-mode .ant-modal-body .ant-radio-disabled + span {
	color: rgba(255, 255, 255, 0.85) !important;
}

.dark-mode .rc-virtual-list-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.5);
}

.dark-mode .ant-modal-body .ant-select-item-option:hover {
	background: #1f1f1f !important;
}

.dark-mode .ant-modal-wrap::-webkit-scrollbar {
	display: none;
}

.dark-mode
	.ant-select
	.ant-select-single.ant-select-show-arrow
	.ant-select-selection-search {
	color: #d6dde3;
}

.dark-mode .document-last-modified-text-mobile {
	color: #ffffffd9;
}

.dark-mode .document-table .ant-select-arrow,
.dark-mode
	.searched-table
	.ant-select
	.ant-select-single.ant-select-show-arrow
	.ant-select-selection-search,
.dark-mode .searched-table .ant-select-arrow {
	color: transparent !important;
}

.dark-mode .selected-field td {
	background: #112a45;
}

/* .dark-mode .ant-table-tbody {
	background-color: transparent;
} */

.dark-mode .ant-table-wrapper .ant-table .ant-table-title {
	background-color: #1f1f1f;
	border-radius: 0;
}

.dark-mode .ant-pagination-disabled .ant-pagination-item-link,
.dark-mode .ant-pagination-disabled:hover .ant-pagination-item-link {
	color: rgba(255, 255, 255, 0.5);
	border-color: #434343;
}
.dark-mode
	.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-ellipsis,
.dark-mode
	.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-ellipsis {
	width: 30px;
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .ant-table-pagination.ant-pagination {
	background-color: #141414;
	border: none;
}

.dark-mode .ant-pagination-prev .ant-pagination-item-link,
.dark-mode .ant-pagination-next .ant-pagination-item-link,
.dark-mode .ant-pagination-item,
.dark-mode .ant-pagination-item a {
	background-color: #303030;
	color: rgba(255, 255, 255, 0.85);
	border-color: #434343;
}

.dark-mode .ant-pagination-item-active a {
	color: #1890ff;
}

.dark-mode .field-table,
.dark-mode .schema-table,
.dark-mode .field-selection-table,
.dark-mode .templates-table,
.dark-mode .preview-table,
/* .dark-mode .measure-list-table, */
.dark-mode .data-source-table {
	border-radius: 0;
	border: 1px solid #303030;
}

.dark-mode .ant-table-thead .ant-table-cell {
	font-family: "TITILLIUM WEB";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
}

.dark-mode .dashboard_wrapper {
	background: url("../img/Portal_DarkBackground.png") no-repeat center;
	background-size: cover;
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .dashboard-select {
	background: #1f1f1f;
}

.dark-mode .config-plot-modal-data-series-btns,
.dark-mode .dataseries-add-button,
.dark-mode .dataseries-delete-button {
	color: #424242;
}

.dark-mode .custom-optional-text {
	color: rgba(255, 255, 255, 0.45);
}

.dark-mode .ant-empty-description {
	color: rgba(255, 255, 255, 0.85) !important;
}

.dark-mode .dashboard-select .ant-select-item-option {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .dashboard-select .ant-select-item-option-selected,
.dark-mode
	.dashboard-select
	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background: #1f1f1f !important;
}

.dark-mode .dashboard-select .ant-select-item-option:hover {
	background: #1f1f1f !important;
}

.dark-mode .button-delete-general {
	background: #141414 !important;
	border: 1px solid #434343 !important;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
	border-radius: 2px !important;
}

.dark-mode .document-table {
	margin: 20px;
	background: #303030;
	box-shadow: none;
	border: 1px solid #141414;
	border-radius: 2px;
}

.dark-mode .ant-modal-footer .button-general,
.dark-mode .ant-modal-footer .button-general:focus,
.dark-mode .ant-modal-body .button-general,
.dark-mode .ant-modal-body .button-general:focus,
.dark-mode .document-table .button-general,
.dark-mode .document-table .button-general:focus {
	color: rgba(255, 255, 255, 0.85);
	background-color: #141414;
	border: 1px solid #434343;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
	border-radius: 2px;
}

.dark-mode .ant-modal-footer .button-general:hover,
.dark-mode .ant-modal-body .button-general:hover,
.dark-mode .document-table .button-general:hover {
	background-color: #141414;
	color: #1765ad;
	border-color: #1765ad;
}

.dark-mode .button-general-disabled {
	background: #1f1f1f !important;
	border: 1px solid #434343 !important;
	border-radius: 2px;
}

.dark-mode .document-table-header {
	background-color: #141414;
	color: rgba(255, 255, 255, 0.85);
	border: none;
}

.dark-mode .document-table-main {
	padding: 5px;
	background: #141414;
	flex: 1;
}

.dark-mode .button-delete-general {
	background: #141414 !important;
	border: 1px solid #434343 !important;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
	border-radius: 2px !important;
}

.dark-mode .ant-btn-dangerous.ant-btn-primary[disabled] {
	color: rgba(255, 255, 255, 0.2);
}

.dark-mode .checkbox-text {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .coming-soon-center-container {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .document-content-header-mobile {
	border-bottom: 1px solid #112a45;
	background: #111d2c;
}

.dark-mode .document-content-header-title-mobile {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .document-sub-title {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .document-container-mobile {
	background: transparent;
}

.dark-mode .document-main-container-mobile {
	background: url("../img/Portal_DarkBackground.png") no-repeat center !important;
	background-size: cover !important;
}

.dark-mode .markdown-container {
	background: url("../img/Portal_DarkBackground.png") no-repeat center !important;
	background-size: cover !important;
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode ellipse {
	fill: #f5f5f5;
}

.dark-mode ellipse + g path:last-child {
	fill: #fafafa;
}

.dark-mode ellipse + g {
	stroke: #d9d9d9;
}

.dark-mode .markdown-bottom-container {
	background-color: #14141455;
}

.dark-mode .markdown-contact-container .divider {
	border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.dark-mode .markdown-contact-container {
	background-color: #14141499;
}

.dark-mode .markdown-notes {
	background: transparent;
}

.dark-mode
	.mobile-menu
	.admin-menu-item
	.adn-menu-item
	.ant-menu-title-content {
	color: rgba(255, 255, 255, 0.85) !important;
}

.dark-mode .drawer-button-close {
	background: transparent;
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .drawer-button-close:hover {
	background: transparent;
	color: rgba(255, 255, 255, 1);
}

.dark-mode .folder {
	border: 1px solid #141414;
	border-radius: 2px;
}

.dark-mode .ant-btn.ant-btn-default.ant-btn-icon-only.folder-buttons {
	background-color: #141414;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dark-mode
	.ant-btn.ant-btn-default.ant-btn-icon-only.folder-buttons:last-child {
	border-right: none;
}

.dark-mode
	.ant-btn.ant-btn-default.ant-btn-icon-only.folder-buttons
	.ant-icon
	.dark-mode
	.folder-buttons:hover {
	background-color: #111d2c;
	color: #177ddc;
}

.dark-mode .folder-buttons:focus {
	background-color: #141414;
}

.dark-mode .folder-buttons:last-child {
	border: none;
}

.dark-mode .folder-footer {
	border-top: 1px solid #141414;
	background-color: #141414;
	border-radius: 2px;
}

.dark-mode .folder-header {
	background-color: #141414;
	border-bottom: 1px solid #141414;
	border-radius: 2px;
}
.dark-mode .folder-title {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .folder-body {
	color: rgba(255, 255, 255, 0.85);
	background-color: #303030;
}

.dark-mode .hexlock {
	background: url("../img/lock_icon_white.png") no-repeat center;
}

.dark-mode .hexlock-container {
	background: transparent;
	border-left: solid 1px rgba(255, 255, 255, 0.85);
	border-right: solid 1px rgba(255, 255, 255, 0.85);
	width: 127px;
}

.dark-mode .hexlock-container:after {
	border-bottom: solid 2px rgba(255, 255, 255, 0.85);
	border-left: solid 2px rgba(255, 255, 255, 0.85);
	bottom: -45.194173824159215px;
}

.dark-mode .hexlock-container:before {
	border-top: solid 2px rgba(255, 255, 255, 0.85);
	border-right: solid 2px rgba(255, 255, 255, 0.85);
	top: -45.194173824159215px;
}
.dark-mode .markdown-container-mobile {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .markdown-container-mobile input {
	border: none !important;
}

.dark-mode .markdown-container-mobile span path {
	fill: #454545;
}

.dark-mode .markdown-bottom-container-mobile {
	background: #14141455;
}

.dark-mode .markdown-container-mobile {
	background: url("../img/Portal_DarkBackground.png") no-repeat center;
	background-size: cover;
}

.dark-mode .markdown-title {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .marketplace-dashboard-search input,
.dark-mode .marketplace-dashboard-search button,
.dark-mode .marketplace-dashboard-input {
	background: #141414;
	border: 1px solid #434343;
	color: #ffffffd9;
}

.dark-mode
	.marketplace-dashboard-input.marketplace-dashboard-input-readonly
	.ant-select-selector
	.ant-select-selection-item,
.dark-mode
	.marketplace-dashboard-input.marketplace-dashboard-input-read-and-edit
	.ant-select-selector
	.ant-select-selection-item {
	background: #262626;
}

.dark-mode
	.marketplace-dashboard-input.marketplace-dashboard-input-readonly
	.ant-select-selector,
.dark-mode
	.marketplace-dashboard-input.marketplace-dashboard-input-read-and-edit
	.ant-select-selector {
	border: none;
	background: none;
	border: none;
	color: #ffffffd9;
}

.dark-mode .marketplace-dashboard-search input::placeholder {
	color: #ffffff73;
}

.dark-mode .marketplace-dashboard-name {
	font-size: 16px;
	color: #ffffffa6;
}

.dark-mode .marketplace-dashboard-description {
	font-size: 13px;
	color: #ffffff73;
}

.dark-mode .marketplace-dashboard-items {
	row-gap: 2px;
}

.dark-mode .marketplace-dashboard-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #141414;
	padding: 16px 24px;
}

.dark-mode .marketplace-dashboard-content {
	display: grid;
	row-gap: 4px;
}

.dark-mode .marketplace-dashboard-buttons {
	font-size: 14px;
}

.dark-mode .marketplace-dashboard-edit,
.dark-mode .marketplace-dashboard-select {
	font-size: 14px;
}

.dark-mode .marketplace-dashboard-settings-button {
	color: rgba(255, 255, 255, 0.85);
	background: #031220;
	border: 1px solid #164c7e;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
	border-radius: 2px;
}

.dark-mode .marketplace-dashboard-settings-button:focus {
	background-color: #031220;
}

.dark-mode .marketplace-dashboard-settings-button:hover {
	background-color: #031220;
}

.dark-mode .marketplace-dashboard-element-dropdown .ant-dropdown-menu-item {
	box-shadow: inset -1px 0 0 0 #177ddc;
	border-radius: 0;
}

.dark-mode
	.marketplace-dashboard-element-dropdown
	.ant-dropdown-menu-item:hover,
.dark-mode
	.marketplace-dashboard-element-dropdown
	.ant-dropdown-menu-item-active {
	background-color: #111d2c;
	color: #177ddc;
	box-shadow: inset -3px 0 0 0 #177ddc;
}

.dark-mode .marketplace-drawer-footer {
	background: #141414;
	box-shadow: inset 0px 1px 0px #031220;
}

.dark-mode .marketplace_drawer_wrapper .ant-drawer-wrapper-body {
	background: #1f1f1f;
	box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
		0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
	border-radius: 2px;
}

.dark-mode .marketplace_drawer_wrapper .ant-drawer-header {
	background: #141414;
	box-shadow: inset 0px -1px 0px #031220;
	border: none;
}

.dark-mode .marketplace_drawer_wrapper .ant-drawer-title,
.dark-mode .marketplace_drawer_wrapper .anticon,
.dark-mode .marketplace_drawer_wrapper .ant-drawer-body {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .marketplace_drawer_wrapper .ant-select-selector,
.dark-mode
	.document-table
	.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	background: #141414;
	border: 1px solid #434343;
	border-radius: 2px;
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .ant-select-disabled .ant-select-selector {
	color: rgba(0, 0, 0, 0.25);
}

.dark-mode
	.document-table
	.ant-select
	.ant-select-single.ant-select-show-arrow
	.ant-select-selection-search {
	color: transparent;
}

.dark-mode .marketplace_drawer_wrapper .ant-drawer-content {
	background-color: rgba(4, 31, 57, 1);
}

.dark-mode .marketplace-tabs .ant-tabs-tab {
	background: transparent !important;
	border: none !important;
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .marketplace-tabs .ant-tabs-tab-active {
	border-bottom: 3px solid #1890ff !important;
	transition: none !important;
}

.dark-mode .marketplace-tabs .anticon,
.dark-mode .document-search .anticon {
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .marketplace-tabs .ant-btn-icon-only,
.dark-mode .document-search .ant-btn-icon-only {
	background-color: #141414;
	border: 1px solid #434343;
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .marketplace-tabs .ant-btn-icon-only:hover,
.dark-mode .document-search .ant-btn-icon-only:hover {
	background-color: #141414;
	border: 1px solid #40a9ff;
	color: #40a9ff !important;
	z-index: 2;
}

.dark-mode .marketplace-tabs .ant-input-affix-wrapper,
.dark-mode .document-search .ant-input-affix-wrapper {
	background: #141414;
	border: 1px solid #434343;
	border-radius: 2px;
}

.dark-mode .marketplace-tabs .ant-input-affix-wrapper:hover,
.dark-mode .document-search .ant-input-affix-wrapper:hover {
	z-index: 2;
}

.dark-mode .marketplace-tabs .ant-input,
.dark-mode .document-search .ant-input {
	background: #141414;
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .marketplace-tabs .ant-tabs-nav::before {
	border-bottom: 1px solid #303030;
}

.dark-mode .mobile-menu .admin-menu-item svg {
	color: #fff !important;
}

.dark-mode .notification-container {
	background: #141414;
}

.dark-mode .notification-content,
.dark-mode .notification-date {
	color: rgba(255, 255, 255, 0.45);
}

.dark-mode .notification-modal {
	box-shadow: 0px 0px 1px 0px rgb(155 155 155);
}

.dark-mode .notification-items {
	border: none;
}

.dark-mode .notification-new-release {
	background: #434343;
}

.dark-mode .notification-image-icon {
	color: #f3b730 !important;
}

.dark-mode .notification-nav-bar {
	background: #141414;
	border: 1px solid #1765adcc;
	color: #1890ff;
}

.dark-mode .notification-nav-bar .ant-menu-item {
	color: rgba(255, 255, 255, 0.65);
	background: #141414;
}

.dark-mode .notification-nav-bar .ant-menu-item-selected {
	background: #141414 !important;
	border-bottom: none;
}

.dark-mode .notification-title {
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .notification-updates {
	color: rgba(255, 255, 255, 0.65);
}
.dark-mode .notification-window {
	color: rgba(255, 255, 255, 0.65);
}

.dark-mode .notification-window div {
	border: none !important;
}

.dark-mode .optional {
	color: #ffffff73;
}

.dark-mode .markdown-container-mobile .ant-input-search-button,
.dark-mode .markdown-container-mobile .ant-input-group-addon,
.dark-mode .markdown-container-mobile .ant-input-affix-wrapper,
.dark-mode .markdown-container-mobile .ant-input {
	background: #1b1d1e;
	border: 1px solid #454545;
	color: #fff;
}

.dark-mode .markdown-container-mobile .ant-input::placeholder {
	color: #ffffffa6;
}

.dark-mode .markdown-container-mobile .ant-input:focus {
	border-color: #4096ff;
}

.dark-mode .primary-btn {
	border-color: #177ddc !important;
	background: #177ddc !important;
	color: #fff !important;
}

.dark-mode .primary-btn:not([disabled]):hover {
	border-color: #1765ad !important;
	background: #1765ad !important;
	color: #fff !important;
}

/* .dark-mode
	.recently-modified-top-container
	.ant-input-group-wrapper
	.ant-input-group-addon
	.ant-input-search-button, */
/* .dark-mode
	.recently-modified-top-container
	.ant-input-group-wrapper
	.ant-input-affix-wrapper,
.dark-mode
	.recently-modified-top-container
	.ant-input-group
	.ant-input-group-addon {
	border: 1px solid #454545;
} */

.dark-mode .recently-modified-top-container .ant-input-group * {
	background: #1b1d1e;
}

.dark-mode
	.recently-modified-top-container
	.ant-input-affix-wrapper
	.ant-input:focus {
	border: none !important;
}

.dark-mode .recently-modified-top-container span:focus {
	outline: none;
}

.dark-mode .role-tags path {
	transition: all 0.3s;
}

.dark-mode .role-tags:hover path {
	fill: #1765ad;
}

.dark-mode .save-button:not([disabled]):hover {
	border-color: #1765ad !important;
	background: #1765ad !important;
}

.dark-mode .silver-tnp-title,
.dark-mode .silver-tnp {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .site-layout-footer {
	background-color: black;
}

.dark-mode .site-tag-plus:hover,
.dark-mode .role-tags:hover {
	border: 1px dashed #1765ad !important;
	color: #1765ad !important;
}

.dark-mode
	.ant-btn.ant-btn-default.vessel_drawer_report_button.vessel-drawer-button,
.dark-mode .ant-btn.ant-btn-default.ant-btn-icon-only.vessel-drawer-button {
	background: #031220;
}

.dark-mode .vessel_drawer_wrapper .ant-drawer-extra {
	color: rgba(255, 255, 255, 0.85);
	background: transparent;
}

.dark-mode .vessel_drawer_wrapper .ant-drawer-header {
	background-color: rgba(4, 31, 57, 0.2);
	border-image: linear-gradient(0deg, #1765ad 80%, #146262 80%) 1;
}

.dark-mode .vessel_drawer_wrapper .ant-drawer-title {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .vessel_drawer_wrapper .ant-drawer-content {
	background: url("../img/Portal_DarkVerticalBackground.png") no-repeat center;
	background-size: cover;
	background-color: rgba(4, 31, 57, 1);
}

.dark-mode .vessel_drawer_wrapper .ant-drawer-extra {
	color: rgba(255, 255, 255, 0.85);
	background: transparent;
}

.dark-mode .vessel-drawer-content-title,
.dark-mode .vessel_drawer_hse_content {
	color: rgba(255, 255, 255, 0.85);
}

.dark-mode .vessel_drawer_wrapper .ant-drawer-wrapper-body {
	background: url("../img/Portal_DarkVerticalBackground.png") no-repeat center;
	background-size: cover;
	background-color: rgba(4, 31, 57, 1);
}

.dark-mode .vessel_3D_image_silver_drawer {
	border: 1px solid;
	border-image: linear-gradient(90deg, #1765ad 80%, #146262 80%) 1;
}

.dark-mode .vessel-drawer-button,
.dark-mode .vessel-drawer-button:focus {
	color: rgba(255, 255, 255, 0.85);
	background-color: #031220;
	border: 1px solid #164c7e;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
	border-radius: 2px;
}

.dark-mode .vessel-drawer-button[disabled] {
	color: rgba(0, 0, 0, 0.5) !important;
	background-color: #1f1f1f !important;
	border: 1px solid #434343 !important;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
	border-radius: 2px;
}

.dark-mode .vessel-drawer-button:hover {
	background-color: #031220;
}

.dark-mode .vessel-button {
	height: 100%;
	border: 0px;
	background-color: transparent;
}

.dark-mode .vessel-button:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.2);
}

.dark-mode .vessel-button-selected {
	height: 100%;
	border: 0px;
	background-color: rgba(0, 0, 0, 0.2);
	border-left: 0.5px solid #1765ad;
	border-right: 0.5px solid #1765ad;
}

.dark-mode .vessel-list-container {
	height: 115px;
	background: linear-gradient(159.18deg, #092034 -62.21%, #061729 123.11%);
	align-items: center;
	overflow-x: auto !important;
	overflow-y: hidden !important;
	display: flex;
	/* margin: auto; */
	margin: 0;
}

.dark-mode .vessel_3D_image_silver_drawer .ant-image .ant-image-img {
	color: white;
}

.dark-mode .vessel-onhire {
	filter: brightness(0) invert(1);
}

.dark-mode .vessel-notonhire {
	filter: brightness(0) invert(0.5);
}

.dark-mode .mobile-menu {
	background-color: #141414;
}

.dark-mode .mobile-menu .user-menu {
	display: flex;
	color: rgba(255, 255, 255, 0.85) !important;
	background: #141414 !important;
}

.dark-mode .user-menu {
	color: rgba(255, 255, 255, 0.85) !important;
	background: #141414 !important;
}

.dark-mode .user-menu:hover {
	background: #111d2c !important;
	border-right: 3px solid #177ddc;
	box-sizing: border-box;
	color: #177ddc;
	transition: none;
}

.dark-mode .unclickable {
	color: rgba(255, 255, 255, 0.45);
}

/* Tags */
.dark-mode .ant-tag-pink {
	color: #e0529c;
	background: #291321;
	border-color: #551c3b;
}
.dark-mode .ant-tag-pink-inverse {
	color: #fff;
	background: #cb2b83;
	border-color: #cb2b83;
}
.dark-mode .ant-tag-magenta {
	color: #e0529c;
	background: #291321;
	border-color: #551c3b;
}
.dark-mode .ant-tag-magenta-inverse {
	color: #fff;
	background: #cb2b83;
	border-color: #cb2b83;
}
.dark-mode .ant-tag-red {
	color: #e84749;
	background: #2a1215;
	border-color: #58181c;
}
.dark-mode .ant-tag-red-inverse {
	color: #fff;
	background: #d32029;
	border-color: #d32029;
}
.dark-mode .ant-tag-volcano {
	color: #e87040;
	background: #2b1611;
	border-color: #592716;
}
.dark-mode .ant-tag-volcano-inverse {
	color: #fff;
	background: #d84a1b;
	border-color: #d84a1b;
}
.dark-mode .ant-tag-orange {
	color: #e89a3c;
	background: #2b1d11;
	border-color: #593815;
}
.dark-mode .ant-tag-orange-inverse {
	color: #fff;
	background: #d87a16;
	border-color: #d87a16;
}
.dark-mode .ant-tag-yellow {
	color: #e8d639;
	background: #2b2611;
	border-color: #595014;
}
.dark-mode .ant-tag-yellow-inverse {
	color: #fff;
	background: #d8bd14;
	border-color: #d8bd14;
}
.dark-mode .ant-tag-gold {
	color: #e8b339;
	background: #2b2111;
	border-color: #594214;
}
.dark-mode .ant-tag-gold-inverse {
	color: #fff;
	background: #d89614;
	border-color: #d89614;
}
.dark-mode .ant-tag-cyan {
	color: #33bcb7;
	background: #112123;
	border-color: #144848;
}
.dark-mode .ant-tag-cyan-inverse {
	color: #fff;
	background: #13a8a8;
	border-color: #13a8a8;
}
.dark-mode .ant-tag-lime {
	color: #a9d134;
	background: #1f2611;
	border-color: #3e4f13;
}
.dark-mode .ant-tag-lime-inverse {
	color: #fff;
	background: #8bbb11;
	border-color: #8bbb11;
}
.dark-mode .ant-tag-green {
	color: #6abe39;
	background: #162312;
	border-color: #274916;
}
.dark-mode .ant-tag-green-inverse {
	color: #fff;
	background: #49aa19;
	border-color: #49aa19;
}
.dark-mode .ant-tag-blue {
	color: #3c9ae8;
	background: #111d2c;
	border-color: #15395b;
}
.dark-mode .ant-tag-blue-inverse {
	color: #fff;
	background: #177ddc;
	border-color: #177ddc;
}
.dark-mode .ant-tag-geekblue {
	color: #5273e0;
	background: #131629;
	border-color: #1c2755;
}
.dark-mode .ant-tag-geekblue-inverse {
	color: #fff;
	background: #2b4acb;
	border-color: #2b4acb;
}
.dark-mode .ant-tag-purple {
	color: #854eca;
	background: #1a1325;
	border-color: #301c4d;
}
.dark-mode .ant-tag-purple-inverse {
	color: #fff;
	background: #642ab5;
	border-color: #642ab5;
}
.dark-mode .ant-tag-success {
	color: #49aa19;
	background: #162312;
	border-color: #274916;
}
.dark-mode .ant-tag-processing {
	color: #177ddc;
	background: #111b26;
	border-color: #153450;
}
.dark-mode .ant-tag-error {
	color: #a61d24;
	background: #2a1215;
	border-color: #58181c;
}
.dark-mode .ant-tag-warning {
	color: #d89614;
	background: #2b1d11;
	border-color: #593815;
}

.dark-mode .ql-container.ql-snow {
	border: 1px solid #434343;
	font-family: "Titillium Web", sans-serif;
	font-size: 14px;
	border-radius: 2px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

.dark-mode .ql-toolbar.ql-snow {
	border: 1px solid #434343;
	border-radius: 2px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.dark-mode .grey-search-bar .ant-input-affix-wrapper,
.dark-mode .grey-search-bar .ant-input,
.dark-mode .grey-search-bar .ant-btn-default {
	background-color: #1f1f1f;
}
