.colChild {
	padding-right: 20px;
	font-size: 12px;
	color: #3e5775;
	text-align: right;
	font-weight: bold;
}

.colMaster {
	display: flex;
	align-items: center;
	padding-left: 20px;
	font-size: 12px;
	color: #3e5775;
	font-weight: bold;
}

.customClusterIcon {
	background-image: url("../img/map_pin.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	filter: drop-shadow(1px 1px 4px #1e1e1e);
}

.leaflet-marker-icon {
	filter: drop-shadow(1px 1px 4px #1e1e1e);
}

.customClusterIcon div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.customClusterIcon .customClusterIconBackground {
	margin-top: 4px;
	background: #add8e6;
	clip-path: circle(40%);
}

.customClusterIcon span {
	display: block;
	text-align: center;
	color: black;
	font-weight: 800;
}

.silver-tnp {
	color: #3e5775;
}

.silver-tnp-title {
	font-weight: bold;
	font-size: 14px;
}

.colChild_drawer {
	font-size: 14px;
	text-align: right;
}

.colMaster_drawer {
	display: flex;
	align-items: center;
	font-size: 14px;
}

.fullCol {
	width: 33.3%;
	height: 40px;
	border-right: 1px solid #dcdcdc;
}

.fullCol:last-child {
	border-right: none;
}

.fullCol:hover {
	background-color: aliceblue;
}

.leaflet-container a.leaflet-popup-close-button {
	color: #0078a8;
}

.leaflet-popup-content {
	width: 250px;
	margin: 0px 0px;
	line-height: 1.5;
}

.leaflet-popup-content-wrapper {
	background: white;
	border-radius: 0px;
}

.rowLight {
	background-color: #413e3e1f;
}

.rowMaster {
	padding: 5px 0px;
	border-bottom: 1px solid #dcdcdc;
}

.rowMaster_drawer {
	padding: 5px 0px;
}

.vessel-button-img {
	height: 30px;
	width: 30px;
	image-rendering: -webkit-optimize-contrast;
}

.vessel-drawer-content-title {
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 600;
	font-family: "Titillium Web";
}

.vesselImage {
	padding-left: 30px;
	padding-right: 30px;
}

.vessel-notonhire {
	filter: brightness(0) contrast(0.3);
}

.vesselStatus {
	background: linear-gradient(0deg, #fff, #f4dcdb) !important;
	/* border: 1px solid grey; */
	/* border-radius: 8px;   */
	/* background-color: white; */
	/* margin: 5px; */
}

.vesselStatusBtn {
	width: 100%;
	height: 100%;
	color: white;
	font-weight: bold;
	border: none;
	background-color: #f5f5f5;
}

.vesselStatusBtn:hover {
	background-color: aliceblue;
}

.vessel_modal_wrapper {
	position: absolute;
	top: 180px;
	bottom: initial;
	left: initial;
	height: calc(100% - 115px - 73px);
}

.vessel_modal_wrapper > .ant-modal > .ant-modal-content {
	height: 100%;
}

.ant-drawer.ant-drawer-right.vessel_drawer_wrapper {
	bottom: auto;
	height: 100%;
	left: auto;
}

.ant-drawer.ant-drawer-right.vessel_drawer_wrapper_mega {
	bottom: auto;
	/* height: calc(100% - 248px); */
	left: auto;
	/* top: 218px; */
}

.vessel_3D_image_silver_drawer .ant-image .ant-image-img {
	height: 125px;
	width: 150px;
}
.vessel_3D_image_silver_drawer .ant-image {
	height: 100%;
	width: 100%;
}

.vessel_drawer_grid {
	display: grid;
	grid-template-columns: 0.4fr 0.6fr;
	grid-auto-columns: auto;
	grid-auto-rows: auto auto;
	row-gap: 20px;
	grid-template-areas:
		"a b"
		"c c"
		"d d"
		"e e";
}

.vessel_drawer_image_container {
	margin-top: 10px;
	grid-area: a;
	display: flex;
	align-content: center;
	align-items: center;
	flex-direction: column;
}

.vessel_drawer_tnp_container {
	padding-left: 20px;
	grid-area: b;
}

.vessel_drawer_report_container {
	padding: 0 24px;
	display: grid;
	grid-template-rows: auto auto auto;
	grid-area: c;
}

.vessel_drawer_report_buttons_container {
	display: grid;
	align-items: center;
	justify-content: space-between;
	grid-template-columns: repeat(3, auto);
}

.vessel_drawer_report_button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 110px;
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
	font-family: "Roboto";
}

.vessel_drawer_report_button .anticon {
	font-size: 18px;
}

.vessel_drawer_smart_contracts_button {
	height: 50px;
	width: 415px;
	padding: 0px 30px;
	font-size: 18px;
}

.vessel_drawer_hse_container {
	padding: 0 24px;
	display: grid;
	grid-template-rows: auto auto;
	grid-area: d;
}

.vessel_drawer_hse_content {
	display: grid;
	grid-template-columns: 0.8fr 0.1fr 0.1fr;
	row-gap: 10px;
	font-size: 14px;
}

.vessel_drawer_chart_container {
	padding: 0 24px;
	display: grid;
	grid-template-rows: auto auto;
	grid-area: e;
}

.vessel_3D_image_silver_drawer {
	border: 1px solid #e7e7e7;
	border-radius: 25px;
	height: 125px;
	width: 150px;
}

.vessel_3d_image_preview {
	background-color: rgba(0, 0, 0, 0.9);
}

.vessel_drawer_wrapper
	> .ant-drawer-content-wrapper
	> .ant-drawer-content
	> .ant-drawer-wrapper-body
	> .ant-drawer-header
	> .ant-drawer-header-title
	> .ant-drawer-title {
	justify-content: initial;
}

.tooltip_map_selected {
	z-index: 1000;
}
