.digital-twin-maximo-overview-content-data {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.digital-twin-maximo-value {
	text-align: end;
	font-weight: 400;
}

.digital-twin-maximo-value.text-start {
	text-align: start;
	font-weight: 400;
}

.digital-twin-maximo-overview-content-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: strech;
	width: 100%;
}

.digitaltwin-right-drawer-tabs .ant-tabs-nav {
	margin: 0;
}

.digitaltwin-table .ant-table-tbody {
	background-color: #1f1f1f;
}

.digitaltwin-table .ant-table-thead > tr > th {
	background-color: #141414;
}
.digitaltwin-table .log-record-row-active,
.digitaltwin-table .log-record-row-active > .ant-table-cell-row-hover {
	background-color: #112a45 !important;
}

.digital-twin-camera-button {
	min-width: 88px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(20, 20, 20, 0.5);
	border: 1px solid #434343;
	padding: 4px 5px;
	cursor: pointer;
}

.digital-twin-camera-button:hover {
	border: 1px solid #1890ff;
}

.digital-twin-camera-button.digital-twin-camera-button-active {
	border: 1px solid #1890ff;
}

.digital-twin-camera-button.digital-twin-camera-button-disabled {
	background-color: rgba(20, 20, 20, 0.2);
	border: 1px solid #434343;
	cursor: not-allowed;
	color: rgba(255, 255, 255, 0.2);
}

.digital-twin-maximo-wo-red-row .ant-table-cell:first-child {
	border-left: 4px solid #ff4d4f;
}

.digital-twin-maximo-wo-grey-row .ant-table-cell:first-child {
	border-left: 4px solid #434343;
}
